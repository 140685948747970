.login-form-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
  .login-form-input-container {
    max-width: 474px;
    width: 100%;
    margin-top: 50px;
    .login-form-password-input {
      margin-top: 30px;
    }
    .login-form-login-button {
      margin-top: 50px;
    }
    .login-form-forgot-password-container {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: flex-end;
      .login-form-forgot-password-link {
        margin-top: 14px;
      }
    }
  }
}
