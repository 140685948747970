@import "../../theme/shared";

.ewp-form-page-title-icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid var(--ion-color-light-10);
  padding-bottom: 40px;
  .ewp-form-page-icon {
    height: 46px;
    width: 46px;
    margin-right: 35px;
    &.client {
      background: url("../../assets/icons/company-form-icon.svg") no-repeat
        center center;
    }
    &.truck {
      background: url("../../assets/icons/truck-form-icon.svg") no-repeat center
        center;
    }
  }
  .ewp-form-page-title-and-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .ewp-form-page-title-div {
      color: var(--ion-color-medium);
    }
  }
}
