.profile-settings-content-container {
  .profile-settings-slide-container {
    justify-content: start;
    display: block;
    width: 100%;

    .profile-settings-slide-content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      &.hidden {
        display: none;
      }

      .profile-settings-name-label {
        margin-left: 30px;
      }

      .profile-settings-container {
        width: 100%;
        margin: 40px 140px auto;
        max-width: 597px;

        .profile-settings-header-container {
          --inner-padding-bottom: 25px;
        }

        .profile-settings-details-container {
          display: flex;
          flex-direction: column;
          padding-top: 50px;
          padding-bottom: 200px;
        }
      }
    }
  }
}
