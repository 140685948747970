@import "./shared";
@import "./custom.scss";
/* Ionic Variables and Theming. For more information, please see
* https://beta.ionicframework.com/docs/theming/
* The app direction is used to include
* rtl styles in your app. For more information, please see
* https://beta.ionicframework.com/docs/layout/rtl
* $app-direction: ltr;
* Ionic Colors
* --------------------------------------------------
* Named colors makes it easy to reuse colors on various components.
* It's highly recommended to change the default colors
* to match your app's branding. Ionic provides eight layered colors
* that can be changed to theme an app. Additional colors can be
* added as well (see below). For more information, please see
* https://beta.ionicframework.com/docs/theming/advanced
* To easily create custom color palettes for your app’s UI,
* check out our color generator:
* https://beta.ionicframework.com/docs/theming/color-generator
*/
/* Additional Ionic Colors
* --------------------------------------------------
* In order to add colors to be used with Ionic components,
* the color should be added as a class with the convention `.ion-color-{COLOR}`
* where `{COLOR}` is the color to be used on the Ionic component
* and each variant is defined for the color. For more information, please see
* https://beta.ionicframework.com/docs/theming/advanced
*/

:root {
  --ion-color-primary: #192f54;
  --ion-color-primary-rgb: 25, 47, 84;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #16294a;
  --ion-color-primary-tint: #304465;

  --ion-color-secondary: #f9f9fc;
  --ion-color-secondary-rgb: 249, 249, 252;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dcdcde;
  --ion-color-secondary-tint: #fbfbfc;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;

  --ion-color-success: #00c000;
  --ion-color-success-rgb: 0, 192, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00a900;
  --ion-color-success-border: #25452d;
  --ion-color-success-tint: #1ac61a;

  --ion-color-warning: #ff6409;
  --ion-color-warning-rgb: 255, 100, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e05808;
  --ion-color-warning-border: #4b372e;
  --ion-color-warning-tint: #ff7422;

  --ion-color-danger: #c00000;
  --ion-color-danger-faded: #f6eef0;
  --ion-color-danger-rgb: 192, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a90000;
  --ion-color-danger-border: #381e23;
  --ion-color-danger-tint: #c61a1a;

  --ion-color-dark: #20242a;
  --ion-color-dark-rgb: 32, 36, 42;
  --ion-color-dark-rgb-5: rgba(32, 36, 42, 0.05);
  --ion-color-dark-rgb-20: rgba(32, 36, 42, 0.2);
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1c2025;
  --ion-color-dark-tint: #363a3f;

  --ion-color-medium: #2c3036;
  --ion-color-medium-rgb: 44, 48, 54;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #272a30;
  --ion-color-medium-border-rgb: rgba(255, 255, 255, 0.03);
  --ion-color-medium-tint: #41454a;

  --ion-color-light: #767480;
  --ion-color-light-10: rgba(118, 116, 128, 0.1);
  --ion-color-light-15: rgba(118, 116, 128, 0.15);
  --ion-color-light-50: rgba(118, 116, 128, 0.5);
  --ion-color-light-rgb: 118, 116, 128;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #686671;
  --ion-color-light-border: #373940;
  --ion-color-light-tint: #84828d;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: var(--ion-color-medium);
  --ion-color-white-contrast-rgb: var(--ion-color-medium-rgb);
  --ion-color-white-shade: #686671;
  --ion-color-white-border: #373940;
  --ion-color-white-tint: #84828d;

  //App background color
  --ion-background-color: #fafafc;
  --ion-background-color-rgb: var(--ion-color-secondary-rgb);

  //App input background color
  --ion-input-background-color: var(--ion-color-tertiary);
  --ion-input-background-color-rgb: var(--ion-color-tertiary-rgb);

  --ion-label-color: var(--ion-color-medium);

  //App text color
  --ion-text-color: var(--ion-color-medium);
  --ion-text-color-rgb: var(--ion-color-medium-rgb);

  --ion-toolbar-background: var(--ion-color-secondary);
  --ion-pre-start-note-background: rgba(255, 200, 9, 0.03);

  --ion-color-gray: #e6e5e9;
  --ion-color-gray-rgb: 230, 229, 233;
  --ion-color-light-gray: rgba(242, 242, 245);
  --ion-color-gray-contrast: #4e545e;
  --ion-color-gray-contrast-rgb: 78, 84, 94;
  --ion-color-gray-shade: #cacacd;
  --ion-color-gray-tint: #e9e8eb;

  --ion-color-disabled: #f2f2f5;
  --ion-color-card-hover: rgba(242, 242, 245, 0.5);
  --ion-color-not-inducted: #4e545e;
  --ion-color-add-pre-start-border: rgba(118, 116, 128, 0.25);
  --ion-color-add-pre-start: rgba(78, 84, 94, 0.05);

  //Calendar Border Color
  --ion-color-border-color: rgba(14, 12, 34, 0.03);
  //Calendar Event Backgrounds:
  --ion-color-day-shift: #ffc809;
  --ion-color-night-shift: #8576ed;
  --ion-color-event-day-shift: rgba(255, 200, 9, 0.15);
  --ion-color-event-night-shift: rgba(133, 118, 237, 0.15);
  --ion-color-event-incomplete: rgba(192, 0, 0, 0.15);
  --ion-color-event-complete: rgba(36, 186, 10, 0.15);
  --ion-color-hover: rgba(36, 186, 10, 0.05);
  --ion-color-event-warning: rgba(255, 100, 9, 0.1);
  --ion-color-event-cancelled: rgba(180, 0, 0, 0.4);
}
/* Shared Variables
* --------------------------------------------------
* To customize the look and feel of this app, you can override
* the CSS variables found in Ionic's source files.
* To view all the possible Ionic variables, see:
* https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-headings-font-weight: 300;
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/* To remove auto fill background. Its ugly. */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

ion-loading {
  --spinner-color: var(--ion-color-primary) !important;
  ion-spinner {
    color: var(--ion-color-primary) !important;
  }
}

ion-searchbar {
  .searchbar-input-container {
    height: 100%;
    min-height: 54px;
    .searchbar-input {
      padding-left: 46px;
      font-size: $ewpParagraphFontSize !important;
      font-weight: $ewpParagraphFontWeight !important;
      color: var(--ion-color-light) !important;
      height: 100%;
      min-height: 54px;
    }
    ion-icon {
      top: 17px !important;
    }
  }
}
