.driver-dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .driver-dashboard-detail-card-container {
    height: 100%;
    width: 100%;
    margin-top: 25px;
  }
  .driver-dashboard-card-container {
    height: 100%;
    width: 100%;
    margin-top: 20px;
    .driver-dashboard-grid {
      height: 100%;
      .driver-dashboard-row {
        height: 100%;
        .driver-dashboard-voc-col {
          padding-left: 10px;
          .driver-dashboard-voc-card {
            height: 100%;
            min-height: 250px;
          }
        }
        .driver-dashboard-change-logs-col {
          padding-left: 10px;
          padding-top: 20px;
        }
        .driver-dashboard-expires-col {
          padding-right: 10px;
          .driver-dashboard-expires-card {
            height: 100%;
            min-height: 250px;
          }
        }
        .driver-dashboard-induction-col {
          padding-right: 10px;
          padding-top: 20px;
          .driver-dashboard-induction-card {
            height: 100%;
            min-height: 250px;
          }
        }
      }
    }
  }
  .driver-dashboard-job-history-card-container {
    height: 100%;
    width: 100%;
    margin-top: 20px;
  }

  .driver-dashboard-note-card {
    margin-top: 20px;
    --inner-padding-end: 0;
    max-height: 500px;
    max-width: 720px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .driver-dashboard-note-card-header {
      border-bottom: 1px solid var(--ion-color-light-10);
      width: 100%;
      padding: 20px;
      display: flex;
      .driver-dashboard-note-card-headerlabel {
        align-self: flex-start;
        text-align: start;
      }
    }
    .driver-dashboard-note-container {
      height: 100%;
      margin-top: 40px;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      max-width: 672px;
      width: 100%;
      .driver-dashboard-note-action-buttons-item {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 20px;
        .driver-dashboard-note-save-button {
          width: 68px;
          height: 30px;
          --box-shadow: none;
          --border-radius: 5px;
        }
      }
    }
  }

  .driver-add-note-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .driver-add-note-button {
      width: 720px;
      height: 68px;
      --box-shadow: none;
      --border-radius: 5px;
      margin-top: 70px;
    }
  }
}

.expiries-history-form-container {
  display: flex;
  flex-direction: column;
  .expiries-history-form-input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .expiries-form-inputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .expiries-history-expiries-type-input {
        width: 400px;
      }
      .expiries-date-input-date {
        width: 400px !important;
      }
      .remove-expiry {
        position: absolute;
        right: -50px;
        top: 9px;
      }
    }

    .driver-exp-dialog-upload-container {
      margin-top: 30px;
      width: 100%;
      .driver-exp-dialog-title-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        .driver-exp-dialog-attachments-add-another-button {
          width: 140px;
          height: 30px;
          border-radius: 4px;
          background: var(--ion-color-gray);
          .driver-exp-dialog-small-add-icon {
            margin-left: 10px;
            position: absolute;
            left: 0;
          }
        }
      }
      .web-upload-list-attachment-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: auto;
        height: 100%;
        width: 100%;
        .web-upload-attachment-container {
          width: 105px;
          position: relative;
          padding-top: 5px;
          .remove-attachment-button {
            height: 24px;
            z-index: 1;
            right: 0;
            --border-radius: 50% !important;
            position: absolute;
            width: 24px;
            --box-shadow: none !important;
            margin-right: 3px;
            .web-upload-attachment-close-icon {
              position: absolute;
              font-size: 18px;
            }
          }
          .web-upload-attachment-avatar {
            --size: 95px;
            --border-radius: 5px;
            margin-top: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }

      .driver-exp-dialog-upload-item {
        --background: rgba(78, 84, 94, 0.05);
        border-radius: 4px;
        border: 1px dashed rgba(118, 116, 128, 0.25);
        height: 100px;
        margin-top: 20px;
        .web-upload-icon-label-container {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100px;
          margin-left: 280px;
          .driver-exp-dialog-upload-label {
            cursor: pointer;
          }
          .driver-exp-dialog-upload-icon {
            margin-right: 12px;
            height: 23px;
            width: 23px;
            background: url("../../../assets/icons/web-upload.svg") no-repeat
              center center;
          }
        }
      }
    }
  }
}
