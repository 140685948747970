@import "../../theme/shared.scss";

.reset-password-success-container {
  max-width: 100%;
  padding: 0px 24px;
  margin-top: 70px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .reset-password-success-title-container {
    padding: 0px 31px;
    align-self: center;
  }
  .reset-password-success-login-button {
    margin-top: 219px;
    margin-bottom: 90px;
  }
}

.reset-password-success-sent-container {
  text-align: center;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .reset-password-success-label {
    margin-top: 35px;
    margin-bottom: 14px;
  }
  .reset-password-success-description {
    color: var(--ion-color-light);
    text-align: center;
    line-height: 19px;
    width: 312px;
  }
}
