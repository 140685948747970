.sub-contractor-company-details-title {
  margin-bottom: 20px;
}
.sub-contractor-personal-details-title {
  margin-top: 40px;
  margin-bottom: 20px;
}
.sub-conractor-contact-person-details-row {
  margin-bottom: 28px;
}
.ewp-sub-contractor-start-col {
  padding-right: 10px;
}
.ewp-sub-contractor-end-col {
  padding-left: 10px;
}
