.forgot-password-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .forgot-password-label {
    margin-top: 35px;
    margin-bottom: 14px;
  }
  .forgot-password-description {
    color: var(--ion-color-light);
    text-align: center;
    line-height: 19px;
    width: 526px;
    text-align: center;
  }
  .forgot-password-sent-button {
    height: 100%;
    width: 100%;
    max-width: 366px;
    max-height: 68px;
    margin-top: 39px;
    --padding-top: 24px;
    --padding-bottom: 24px;
    --box-shadow: none;
    --border-radius: 5px;
  }
  .forgot-password-didnt-receive-label {
    margin-top: 80px;
    margin-bottom: 14px;
  }
  .forgot-password-didnt-receive-description {
    color: var(--ion-color-light);
    text-align: center;
    line-height: 19px;
    width: 526px;
    text-align: center;
  }
}
