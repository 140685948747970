$tabletMaxWidth: 1024px;
$mobileMaxWidth: 500px;
$ewpContentMaxWidth: 1700px;

$ewpFontName: Helvetica;
$ewpFontSizeSmall: 12px;
$ewpFontSizeNormal: 17px;
$ewpFontSizeLarge: 36px;
$ewpBold: 700;

//WEB
$ewpWebFontSizeXSmall: 13px;
$ewpWebFontSizeSmall: 15px;
$ewpWebClearButtonFontSize: 16px;
$ewpWebFontSizeNormal: 18px;
$ewpWebTitleFontSize: 20px;

$bottomHeight: 80px;

//LINK CONTSANTS
$ewpLinkColor: var(--ion-color-light);
$ewpLinkHeight: 20px;
$ewpLinkVerticalAlign: middle;
$ewpLinkLetterSpacing: -0.29px;
$ewpLinkTextAlign: center;

$ewpSmallLinkLineHeight: 17px;

//LABEL CONSTANTS
$ewpLabelHeight: 20px;
$ewpLabelFontSize: 17px;
$ewpLabelVerticalAlign: middle;

$ewpSmallLabelColor: var(--ion-color-primary);
$ewpSmallLabelLetterSpacing: -0.29px;
$ewpSmallLabelTextAlign: center;
$ewpSmallLabelLineHeight: 17px;
$ewpLargeLabelHeight: 43px;
$ewpLargeButtonPadding: 35px;

$ewpExtraLargeIcon: 50px;

//COMPANY REP WEB
$ewpCompanyRepHeaderHeight: 70px;
$ewpCompanyRepCompanyNameButtonWidth: 287px;
$ewpCompanyRepSitesButtonWidth: 287px;
$ewpIconHeight: 22px;
$ewpIconWidth: 22px;
$ewpLargeIconHeight: 36px;
$ewpLargeIconWidth: 36px;
$ewpLogoHeight: 37px;
$ewpLogoWidth: 105px;
$ewpWebFontName: Roboto;
$ewpAvatarNavHeight: 40px;
$ewpAvatarNavWidth: 40px;

//COMPANY REP WEB HEADER
$ewpWebHeaderFontSize: 13px;
$ewpWebHeaderFontWeight: 500;

$ewpWebHeaderOptionItemFontSize: 13px;
$ewpWebHeaderOptionItemFontWeight: 500;

$ewpWebHeaderUserNameFontSize: 20px;
$ewpWebHeaderUserNameFontWeight: 500;

$ewpWebHeaderAccountCompanyNameFontSize: 13px;
$ewpWebHeaderAccountCompanyNameFontWeight: 400;

//COMPANY REP WEB SUB HEADER
$ewpSubHeaderHeight: 100px;
$ewpSubHeaderFontSize: 24px;
$ewpSubHeaderFontWeight: 500;
$ewpSubHeaderFontColor: var(--ion-color-medium);

//WEB GLOBAL
$ewpWebSmallButtonFontSize: 14px;
$ewpWebSmallButtonFontWeight: 500px;

//WEB ICONS
$ewpIconNavigatorHeight: 23px;
$ewpIconNavigatorWidth: 23px;
$ewpIconCardHeight: 30px;
$ewpIconCardWidth: 30px;
$ewpIconContainerHeight: 50px;
$ewpIconContainerWidth: 50px;

//TEXT STYLES
$ewpHugeFontSize: 54px;
$ewpHugeFontWeight: 500;
$ewpHugeLineHeight: 63px;

$ewpExtraHugeFontSize: 108px;
$ewpExtraHugeFontWeight: 500;

$ewpH1FontSize: 24px;
$ewpH1FontWeight: 700;
$ewpH1LightFontWeight: 400;
$ewpH1LineHeight: 28px;

$ewpH2FontSize: 20px;
$ewpH2FontWeight: 500;
$ewpH2LineHeight: 24px;

$ewpH3FontSize: 18px;
$ewpH3FontSmallSize: 14px;
$ewpH3FontWeight: 500;
$ewpH3FontBoldWeight: 700;
$ewpH3LineHeight: 21px;

$ewpH4FontSize: 16px;
$ewpH4NormalFontWeight: 400;
$ewpH4FontWeight: 500;
$ewpH4LineHeight: 19px;

$ewpH5FontSize: 13px;
$ewpH5FontWeight: 500;
$ewpH5NormalFontWeight: 400;
$ewpH5BoldFontWeight: 700;
$ewpH5LineHeight: 15px;

$ewpH6FontSize: 11px;
$ewpH6SmallFontSize: 10px;
$ewpH6FontWeight: 500;
$ewpH6LineHeight: 13px;

$ewpTemperatureHugeFontSize: 110px;
$ewpTemperatureHugeFontWeight: 300;

$ewpBodyFontSize: 15px;
$ewpBodyFontWeight: 400;
$ewpBodyLineHeight: 24px;

$ewpParagraphFontSize: 13px;
$ewpParagraphFontWeight: 400;
$ewpParagraphFontWeightMedium: 500;
$ewpParagraphFontWeightBold: 700;
$ewpParagraphLineHeight: 19px;

$ewpCalendarDayFontSize: 12px;
$ewpCalendarDayFontWeight: 400;

$ewpCalendarWeekDayFontSize: 11px;
$ewpCalendarWeekDayFontWeight: 500;

$ewpImageFontSize: 26px;
$ewpImageFontWeight: 500;

$ewpImageLargeFontSize: 50px;
$ewpImageLargeFontHeight: 59px;
$ewpImageLargeFontWeight: 500;

$verifyEmailIconHeight: 108px;
$verifyEmailIconWidth: 108px;

$ewpBlacklistDialogSpacing: 36px;
