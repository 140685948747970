.faults-and-defects-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .faults-and-defects-header {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    .search-button-input-container {
      display: flex;
      flex-direction: row;
      .search-category-button {
        min-height: 56px;
        min-width: 190px;
        border: 1px solid var(--ion-color-light-10);
        --background: var(--ion-color-white);
        --box-shadow: none !important;
        .search-button-icon {
          margin-left: 10px;
          height: 15px;
          width: 15px;
        }
      }
      .ewp-title-search-button-search-bar {
        --background: var(--ion-color-white);
        max-width: 407px;
        min-height: 58px;

        align-self: flex-end;
        border: 1px solid var(--ion-color-light-10);
        border-radius: 4px;
        input {
          box-shadow: none !important;
        }
      }
    }
    .faults-and-defects-right-options-container {
      display: flex;
      align-items: center;
      ion-label {
        margin-right: 10px;
      }
      .faults-and-defects-add-button {
        margin-left: 20px;
        --border-radius: 5px;
        height: 54px;
        width: 200px;
        .faults-and-defects-add-icon {
          color: white;
          height: 18px;
          width: 18px;
          position: absolute;
          left: 13px;
          background: url("../../../assets/icons/add-icon.svg") no-repeat center
            center;
        }
      }
    }
  }

  .faults-and-defects-tickets-container {
    margin-top: 10px;
    display: flex;
    height: 100%;

    .faults-and-defects-spinner-container {
      height: 51vh;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .opened-tickets,
    .in-progress-tickets,
    .closed-tickets,
    .waiting-on-parts-tickets {
      height: 100%;
      width: 25vw;
      &.ipad {
        width: 30vw;
      }
      .ticket-header {
        border-radius: 5px;
        &.opened {
          --background: var(--ion-color-danger);
        }
        &.in-progress {
          --background: var(--ion-color-warning);
        }
        &.closed {
          --background: var(--ion-color-success);
        }
        &.waiting-on-parts {
          --background: var(--ion-color-medium);
        }
      }
      .ticket-card-item {
        --inner-padding-end: 0px;
        .ticket-card {
          margin: 5px;
          margin-bottom: 15px;
          width: 100%;

          .ticket-card-header {
            display: flex;
            flex-direction: column;
            position: relative;
            // justify-content: space-between;
            // align-items: center;
            .status-chip {
              --border-radius: 15px;
              --box-shadow: none;
              margin-top: 5px;
            }

            .ticket-card-edit {
              position: absolute;
              width: 40px;
              height: 40px;
              top: 0;
              right: 0;
            }
            .ticket-card-notes {
              position: absolute;
              width: 40px;
              height: 40px;
              top: 2px;
              right: 40px;
            }
            .ticket-card-notes-counter {
              position: absolute;
              right: 47px;
              top: 12px;
              z-index: 1;
              background: var(--ion-color-medium);
              border-radius: 50%;
              width: 15px;
              height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .request-parts-button {
            width: 100%;
            --box-shadow: none;
            // --border-radius: 50px;
          }
          .ticket-description {
            &.more {
              white-space: normal;
            }
          }
          .view-more {
            width: 71px;
          }

          .ticket-attachment-container {
            // width: 105px;
            margin: auto;
            position: relative;
            // padding-right: 10px;
            .ticket-attachment-avatar-button {
              height: 100%;
              width: 100%;
              margin-top: 10px;

              .ticket-attachment-avatar {
                --size: 100%;
                --border-radius: 5px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                }
              }
            }
          }

          .action-buttons {
            margin-top: 10px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            .action-button {
              --box-shadow: none;
            }
          }
        }
      }
    }
  }
}
