.confirm-dialog {
  --height: 320px;
  --width: 575px;
  --border-radius: 4px;
  --backdrop-opacity: 0.8;

  &::part(content) {
    max-height: 100%;
    width: auto;
    max-height: 100%;
    position: relative;
    contain: content;
    justify-content: flex-start;
    .modal-content {
      overflow: auto;
    }
  }
  .confirm-dialog-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .confirm-dialog-header-close {
      margin-top: 24px;
      margin-right: 24px;
      height: 24px;
      width: 24px;
    }
  }
  .confirm-dialog-title-container {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 48px;
    padding: 0 20px;
    max-height: 115px;
    .confirm-dialog-title {
      width: 100%;
    }
    .confirm-dialog-description {
      margin-top: 12px;
      margin-left: 41px;
      margin-right: 41px;
    }
  }
  .confirm-dialog-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 65px 0px 60px;
    .confirm-button {
      width: 179px;
      height: 44px;
      --border-radius: 5px;
    }
  }
}
