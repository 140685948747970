.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .reset-password-title {
    margin-top: 120px;
  }
  .reset-password-description {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .reset-password-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .reset-password-new-input-container {
      width: 474px;
      height: 68px;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .reset-password-confirm-input-container {
      width: 474px;
      height: 68px;
    }
  }
}
