.web-change-password-title {
  margin: 40px 0px 0px 60px;
}

.web-change-password-container {
  width: 40%;
  margin: 40px 200px auto;
  max-width: 597px;
  .web-change-password-input-container {
    margin-bottom: 30px;
  }
}
