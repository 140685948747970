.ewp-expiry-row {
  margin-bottom: 28px;
}
.ewp-expiry-start-col {
  padding-right: 10px;
}
.ewp-expiry-end-col {
  padding-left: 10px;
  &.delete {
    display: flex;
    justify-content: flex-end;
  }
  .delete-expiry-button {
    --border-radius: 4px;
    max-width: 163px;
    height: 30px;
    width: 100%;
    margin-bottom: 30px;
  }
}
