.ewp-rbcalendar-custom-toolbar-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 25px;
  background-color: var(--ion-color-tertiary);
  .ewp-rbcalendar-today-button {
    width: 63px;
    height: 31px;
    border: 1px solid var(--ion-color-light-10);
    border-radius: 4px;
  }
  .ewp-rbcalendar-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ewp-rbcalendar-nav {
      background: var(--ion-color-light-10);
      border-radius: 4px;
      height: 41px;
      width: 51px;
    }
    .ewp-rbcalendar-label {
      margin: 0px 30px;
    }
  }
  .ewp-rbcalender-onview-button-container {
    .ewp-rbcalendar-view-button {
      width: 63px;
      height: 31px;
      --border-radius: 4px;
      &.work_week {
        width: 100px;
      }
    }
  }
}
.ewp-rbcalendar-sub-toolbar-container {
  padding: 15px 24px;
  background-color: var(--ion-color-tertiary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .ewp-job-toolbar-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .ewp-rbcalendar-button-container {
      // width: 100%;
      .ewp-rbcalendar-sub-tool-button {
        height: 31px;
        width: 63px;
        border-radius: 4px;
        background: var(--ion-color-light-gray);
        &.active {
          background: var(--ion-color-primary);
        }
      }
      .ewp-rbcalendar-sub-tool-options-button {
        height: 31px;
        // width: 63px;
        border-radius: 100px;
        background: var(--ion-color-light-gray);
        &.active {
          background: var(--ion-color-primary);
        }
      }
    }
  }

  .ewp-job-toolbar-subheader-container {
    display: flex;
    justify-content: space-between;
    .ewp-job-legends-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: space-between;
      .ewp-job-legend-item-container {
        width: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: space-between;
        .ewp-job-legend-icon {
          border-radius: 50%;
          height: 14px;
          width: 14px;
          &.day {
            background-color: var(--ion-color-day-shift) !important;
          }
          &.night {
            background-color: var(--ion-color-night-shift) !important;
          }
          &.incomplete {
            background-color: var(--ion-color-danger) !important;
          }
          &.complete {
            background-color: var(--ion-color-primary) !important;
          }
          &.cancelled {
            background-color: var(--ion-color-danger) !important;
          }
          &.complete {
            background-color: var(--ion-color-primary) !important;
          }
          &.pencilled {
            background-color: var(--ion-color-light-50) !important;
          }
          &.incomplete {
            background-color: pink !important;
          }
        }
        .ewp-job-legend-label {
          margin-left: 4px;
          &.day {
            color: var(--ion-color-day-shift) !important;
          }
          &.night {
            color: var(--ion-color-night-shift) !important;
          }
          &.cancelled {
            color: var(--ion-color-danger) !important;
          }
          &.complete {
            color: var(--ion-color-primary) !important;
          }
          &.pencilled {
            color: var(--ion-color-light-50) !important;
          }
          &.incomplete {
            color: pink !important;
          }
        }
      }
    }
    .ewp-rbcalendar-export-button {
      height: 31px;
      width: 45px;
      border-radius: 4px;
      border: 1px solid var(--ion-color-gray);
      margin: 5px 10px;
      .export-icon {
        height: 15px;
        width: 18px;
        background: url("../../../assets/icons/export-icon.svg") no-repeat
          center center;
      }
    }
  }
}
