.ewp-header-mobile {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  position: relative;
  &:after {
    display: none;
  }

  .ewp-header-mobile-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    --border-width: 0 !important;
    --min-height: 72px;
    padding: calc(24px + var(--ion-safe-area-top)) 24px 24px 24px;

    .ewp-header-mobile-back-button-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      .ewp-header-mobile-back-button {
        width: 32px;
        height: 32px;
        --border-radius: 50%;
        &.dark {
          --background: var(--ion-color-dark-rgb-5) !important;
        }

        .ewp-button-back-icon {
          height: 20px;
          color: white;
          margin-right: 3px;
        }
      }
    }

    .ewp-header-mobile-title-container {
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .ewp-title-container {
        height: 48px;
        display: flex;
        align-items: flex-end;
      }

      .ewp-header-mobile-subtitle {
        z-index: 11;
        width: 100%;
      }
    }
    .ewp-header-mobile-option-button-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      .ewp-header-mobile-option-button {
        --border-radius: 50%;
        width: 32px;
        height: 32px;
        --background: var(--ion-color-dark-rgb-5) !important;
        color: white;
      }
    }
  }

  .ewp-subheader-toolbar {
    --padding-bottom: 0.55px;
  }
}
