.truck-form-container {
  display: flex;
  flex-direction: column;
  max-width: 597px;
  .truck-form-header {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .truck-form-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &.truck-form-select-container {
      width: 100%;
      max-width: 597px;
      &.dates {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
      }
    }
  }

  .truck-form-save-button {
    margin-top: 50px;
    height: 68px;
  }
}
