.driver-maintenance {
  visibility: hidden;

  &.safe-to-view {
    visibility: visible;
    opacity: 1 !important;
  }

  .submit-defect-page-content {
    width: 100%;
    padding: 25px 25px 25px 25px;
    height: 100%;
    .submit-defect-label-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      &.value {
        margin-left: 5px;
      }
    }
    .defect-truck-dropdown-container {
      width: 100%;
      max-width: 400px;
      margin: 20px 0px 0px 0px;
    }
    .submit-defect-input-container {
      margin-top: 20px;
      width: 100%;
      &.button-submit {
        height: 100%;
        max-height: 100px;
      }
    }
    .defect-urgency {
      width: 100%;
      max-width: 130px;
    }
    .defect-add-files-button {
      background-color: var(--ion-color-tertiary);
      border: 2px dashed var(--ion-color-light-15);
      // max-width: 327px;
      width: 100%;
      height: 87px;
      border-radius: 10px;
      .add-files-icon {
        height: 24px;
        width: 24px;
        background: url("../../../assets/icons/add-files-icon.svg") no-repeat
          center center;
      }
      .add-files-label {
        margin-left: 7px;
      }
    }
    .driver-job-detail-site-docket-details-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .job-site-docket-date-label {
        text-align: left;
        margin-bottom: 10px;
        align-self: flex-start;
      }
      .job-site-docket-add-files-button {
        background-color: var(--ion-color-tertiary);
        border: 2px dashed var(--ion-color-light-15);
        max-width: 327px;
        width: 100%;
        height: 87px;
        border-radius: 10px;
        .add-files-icon {
          height: 24px;
          width: 24px;
          background: url("../../../assets/icons/add-files-icon.svg") no-repeat
            center center;
        }
        .add-files-label {
          margin-left: 7px;
        }
      }
      .job-site-docket-not-applicable-container {
        width: 100%;
        --padding-start: 0;
        --inner-padding-start: 0;
        .job-site-docket-not-applicable {
          margin-left: 3px;
          // --background: #f3f3f7;
          --border-radius: 4px;
          // --border-color: #f3f3f7;
          --size: 21px;
        }
      }
      .site-docket-attachment-grid {
        width: 100%;
        .site-docket-attachment-row {
          height: calc(100vw / 3.2);
          .site-docket-attachment-img-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            margin-right: 5px;
            width: 100%;
            .site-docket-attachment-img {
              height: 100%;
              max-height: 93px;
              object-fit: cover;
              overflow: hidden;
              position: relative;
              border-radius: 5px;
              margin-right: 5px;
              width: 90%;
            }

            .site-docket-delete-attachment {
              color: white;
              height: 20px;
              width: 20px;
              --border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}
