.driver-register-container {
  --padding-bottom: 224px;
  .driver-register-form-page-container {
    max-width: 600px;
    width: 100%;

    .driver-form-container {
      width: 100%;
      max-width: 597px;
      .driver-form-header-container {
        --inner-padding-bottom: 25px;
        .driver-form-name-label {
          margin-left: 25px;
        }
      }

      .driver-form-details-container {
        padding-top: 50px;
        padding-bottom: 200px;
      }
    }
  }
}
