@import "../../theme/shared.scss";

.user-account-form-title-container {
  margin: 0 16px 34px 16px;
}

.user-account-form {
  width: 100%;
  max-width: 597px;
  .user-account-sign-in-option {
    margin: 18.5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-account-sign-in-spiel {
      vertical-align: middle;
      margin-right: 3px;
    }
  }

  .user-details-input-container {
    margin-bottom: 20px;
  }

  .user-detail-email-container {
    display: flex;
    flex-direction: column;
    height: 19px;
    margin-bottom: 17px;
    .user-detail-resend-email-verification {
      height: 100%;
      align-self: flex-end;
      margin-top: 2px;
      font-family: $ewpFontName;
      font-size: $ewpParagraphFontSize;
      font-weight: $ewpParagraphFontWeight;
      text-align: right;
      line-height: 19px;
      --padding-end: 0;
    }
  }

  .ewp-user-change-password,
  .ewp-sign-in-history {
    border-top: 1px solid var(--ion-color-medium);
    border-bottom: 1px solid var(--ion-color-medium);
    margin-top: 20px;
    padding: 5px 0 5px 0;
  }

  .web-ewp-user-change-password {
    cursor: pointer;
    border-top: 1px solid var(--ion-color-light-10);
    border-bottom: 1px solid var(--ion-color-light-10);
    margin-top: 60px;
  }
  .ewp-label-change-password {
    font-size: $ewpWebClearButtonFontSize !important;
    font-weight: 500;
    margin: 26px 109px 26px 16px;
  }

  .ewp-phone-number {
    padding-top: 70px;
    max-width: 597px;
    .ewp-edit-phone-number {
      font-size: $ewpWebClearButtonFontSize;
      margin-bottom: 16px;
      align-self: flex-end;
      --padding-end: 0;
    }
  }
}
.end-form-spacing {
  z-index: 1;
  --inner-padding-end: 0;
  .user-form-submit-button-container {
    margin-top: 33.5px;
  }
}
.mobile-label {
  padding-top: 16px !important;
  font-size: $ewpWebFontSizeNormal;
}
.mobile-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.user-account-sign-in-terms-and-privacy {
  margin-top: 35px;
}
