.nav-content-grid-container {
  height: 100%;
  .nav-content-row-container {
    height: 100%;
  }
  .nav-content-col-container {
    padding: 0;
    height: auto;
    background-color: var(--ion-color-tertiary);
    width: 256px !important;
    max-width: 256px !important;
  }

  .account-settings-container {
    padding: 40px 71px 100px 60px;
  }
}
