.driver-sched-page {
  visibility: hidden;
  &.safe-to-view {
    visibility: visible;
    opacity: 1 !important;
  }
  .driver-sched-slide-container {
    overflow-y: auto;
    height: 100% !important;
    .driver-sched-slide-view-schedule,
    .driver-sched-slide-dockets {
      --height: 100% !important;
    }
    .job-list-container {
      width: 100%;
      .driver-job-schedule-button-toggle-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // width: 100%;
        .driver-job-schedule-button-toggle-box-item {
          max-width: 100%;
          --inner-padding-end: 0;
          // margin-right: 20px;
          .driver-job-schedule-button-toggle-checkbox {
            margin-left: 11px;
            --background: #f3f3f7;
            --border-radius: 4px;
            --border-color: #f3f3f7;
            --size: 21px;
          }
          .driver-job-schedule-button-toggle-checkbox-label {
            flex: none;
            margin-left: 11px;
            padding-right: 11px;
          }
        }
      }
    }
  }
}
