.ewp-large-button-border {
  --border-width: 15px;
  --border-style: solid;
}

.ewp-large-button {
  //! Removed. This will mess up if put on a container with 100% height. height: inherit;
  width: inherit;
  --padding-top: 25px;
  --padding-bottom: 25px;
  --border-radius: 5px;
}
