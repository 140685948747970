.client-form-company-details-title {
  margin-bottom: 20px;
}
.client-form-grid {
  margin-top: 20px;
}
.client-form-personal-details-title {
  margin-top: 40px;
  margin-bottom: 20px;
}
.client-form-contact-person-details-row {
  margin-bottom: 28px;
}
.ewp-client-form-start-col {
  padding-right: 10px;
}
.ewp-client-form-end-col {
  padding-left: 10px;
}

.ewp-modal-save-form-button {
  &.page-form {
    width: 100% !important;
    height: 68px !important;
    --box-shadow: none !important;
  }
}
