@import "../../theme/shared.scss";

.user-invite-success-dialog {
  &::part(content) {
    --border-radius: 5px;
    height: 405px;
    width: 697px;
    box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  }
  .user-invite-success-content {
    --padding-top: 50px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;
    display: flex;
    flex-direction: column;
    text-align: center;

    .user-invite-success-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .user-invite-success-title-container {
      display: flex;
      flex-direction: column;

      .user-invite-success-subtitle {
        margin-top: 13px;
      }
    }

    .user-invite-success-dialog-container {
      --inner-padding-end: 0;
      margin-right: $ewpBlacklistDialogSpacing;
      margin-left: $ewpBlacklistDialogSpacing;
      margin-top: 35px;
      height: 110px;
      box-shadow: none !important;
      .user-invite-success-details-car-dialog {
        width: 100%;
      }

      .user-invite-success-details-item-dialog {
        --background: var(--ion-color-white);
        --inner-padding-end: 0;
        --padding-start: 0;

        .user-invite-success-profile-icon {
          height: 54px;
          width: 54px;
          margin: 28px 40px 25px 27px;
          background: url("../../assets/icons/users-primary-large.svg")
            no-repeat center center;
        }

        .user-invite-success-item-details-dialog {
          align-self: center;

          .user-invite-success-assign-to-subcontractor-dialog {
            --border-width: 0 0 1px 0;
            --border-style: solid;
            --border-radius: 0;
            height: 15px;
            vertical-align: text-top;
          }
        }

        .user-invite-success-action-item-dialog-container {
          --background: var(--ion-color-white);
          --inner-padding-end: 0;
          --padding-start: 0;
          .user-invite-success-contact-info-dialog {
            padding-left: 40px;
            .user-invite-success-contact-info-email {
              width: 200px;
              .user-invite-success-details-message-icon {
                height: 18px;
                width: 18px;
                margin-right: 7px;
                vertical-align: middle;
                background: url("../../assets/icons/message.svg") no-repeat
                  center center;
              }
              .user-invite-success-details-key-icon {
                height: 18px;
                width: 18px;
                margin-right: 7px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
    .user-invite-done-button {
      margin-top: 50px;
    }
  }
}
