@import "../../../theme/shared.scss";

.attachment-dialog {
  &::part(content) {
    --max-height: 683px;
    --max-width: 697px;
    --height: 100%;
    --width: 100%;
    --border-radius: 5px;
    --box-shadow: none;
  }

  .attachment-dialog-header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 20px 24px;
    border-bottom: 1px solid var(--ion-color-light-10);
    .attachment-dialog-header-container {
      display: flex;
      align-items: center;
      flex-direction: row;

      .attachment-dialog-header-close {
        position: absolute;
        margin-right: 20px;
        right: 0;
        height: 16px;
        width: 16px;
      }
      .attachment-dialog-title-html {
        width: 100%;
        p {
          margin: 0;
          font-size: $ewpH4FontSize !important;
          font-weight: $ewpH4FontWeight !important;
          color: var(--ion-color-medium) !important;
        }
      }
    }
    &:after {
      height: 0px;
    }
  }

  .attachment-dialog-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .attachment-image {
      width: 680px;
      height: 420px;
      margin: 20px 8px;
      border-radius: 5px;
      position: relative;

      &.pdf {
        height: 397px;
      }

      .lb-container {
        z-index: unset !important;
        height: 75%;
        top: 60px;
      }

      .lb-header {
        // display: none;
        // background: var(--ion-color-primary);
        .lb-button {
          &.close {
            display: none;
          }
        }
      }
      .lb-canvas {
        .lb-img {
          max-height: 400px;
        }
      }
    }
    .view-file-link {
      position: absolute;
      bottom: 55%;
      left: 44%;
    }

    .attachment-prev-button {
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 17px;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 420px;
      width: 54px;
      z-index: 1;
    }
    .img-download-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .download-button {
        height: 30px;
        width: 120px;
        --box-shadow: none;
        --border-radius: 4px;
      }
    }
    .attachment-next-button {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 17px;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 420px;
      width: 54px;
      z-index: 1;
    }

    .attachment-next-prev-icon {
      width: 40px;
      height: 40px;
    }

    .attachment-image-list {
      display: flex;
      margin: 24px;
      height: 80px;
      overflow-y: hidden;
      .attachment-image-item-button {
        height: 80px;
        width: 80px;
        border-radius: 5px;
        margin-right: 20px;
        .attachment-image-item {
          height: 80px;
          width: 80px;
        }
      }
    }
  }
}
