.ewp-datepicker-container {
  width: auto;
}
.ewp-datepicker-label {
  position: absolute;
  z-index: 9;
  padding-left: 15px;
  padding-top: 10px;
}
.ewp-datepicker-action-button {
  --border-radius: 5px;
  height: 56px;
  min-width: 236px;
  width: 100%;
  --border-color: var(--ion-color-light-10);
  &.has-error {
    color: var(--ion-color-danger) !important;
    --border-color: var(--ion-color-danger) !important;
  }
  &.date-button {
    --background: var(--ion-color-white);
    --background-hover: var(--ion-color-light-15);
    --background-activated: var(--ion-color-gray-shade);
    --box-shadow: none;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-light-10);
    --border-radius: 4px;
  }
  &.has-label {
    --padding-top: 20px;
  }

  &.ewp-datepicker-today-button {
    margin-right: 20px;
    width: 72px;
  }

  &.ewp-datepicker-calendar-button {
    .ewp-datepicker-calendar-text {
      position: absolute;
      left: 0;
      margin-left: 10px;
      pointer-events: none;
    }
  }
  .ewp-datepicker-calendar-icon {
    height: 18px;
    width: 18px;
    position: absolute;
    right: 0px;
    background: url("../../assets/icons/calendar.svg") no-repeat center center;
  }
}
