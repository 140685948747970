.client-register-container {
  --padding-bottom: 224px;
  .client-register-form-page-container {
    max-width: 600px;
    width: 100%;
    .client-register-form-input-container {
      margin-top: 40px;
      text-align: start;
    }
  }
}
