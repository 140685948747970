@import "./rbc-native-style/styles.scss";

//HIDE the time slot RBCalendar
.rbc-time-content,
.rbc-time-header-gutter {
  display: none !important;
}

.rbc-time-header {
  height: 100%;

  .rbc-time-header-content {
    border-left: none;
  }
}

.rbc-allday-cell {
  min-height: 1120px;
}

.job-schedule-calendar-card {
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
  &.ipad {
    max-height: 800px !important;
  }
  &.web {
    min-height: 1120px !important;
  }
  .job-schedule-calendar {
    height: 100%;
    background-color: var(--ion-color-tertiary);
    min-height: 600px;
    .rbc-time-view {
      &.day {
        width: 70% !important;
      }
    }
    .background-calendar-container {
      &.past-date {
        background-color: var(--ion-color-gray) !important;
      }
    }
    .rbc-today {
      background-color: transparent;
    }

    .job-schedule-calendar-event-label-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;

      // todo: if final design is ready enable this background to gauge the multi days
      &.day {
        background-color: var(--ion-color-event-day-shift) !important;
        &.completed {
          background-color: var(--ion-color-event-complete) !important;
        }
        &.incomplete {
          background-color: pink !important;
        }
        &.cancelled {
          background-color: var(--ion-color-danger) !important;
        }
        &.pencilled {
          background-color: var(--ion-color-light-50) !important;
        }
      }
      &.night {
        background-color: var(--ion-color-event-night-shift) !important;
        &.completed {
          background-color: var(--ion-color-event-complete) !important;
        }
        &.incomplete {
          background-color: pink !important;
        }
        &.cancelled {
          background-color: var(--ion-color-danger) !important;
        }
        &.pencilled {
          background-color: var(--ion-color-light-50) !important;
        }
      }
      .job-schedule-calendar-event-label {
        width: 100px;
      }

      .job-schedule-calendar-status-job-schedule {
        height: 8px;
        width: 8px;
        margin-right: 5px;
        border-radius: 50%;
        &.day {
          background-color: var(--ion-color-day-shift) !important;
          &.completed {
            background-color: var(--ion-color-primary) !important;
          }
          &.incomplete {
            background-color: palevioletred !important;
          }
          &.cancelled {
            background-color: var(--ion-color-danger) !important;
          }
          &.pencilled {
            background-color: var(--ion-color-light-50) !important;
          }
        }
        &.night {
          background-color: var(--ion-color-night-shift) !important;
          &.completed {
            background-color: var(--ion-color-primary) !important;
          }
          &.incomplete {
            background-color: palevioletred !important;
          }
          &.cancelled {
            background-color: var(--ion-color-danger) !important;
          }
          &.pencilled {
            background-color: var(--ion-color-light-50) !important;
          }
        }
      }
    }
    .job-schedule-calendar-event-label-container .tooltiptext {
      visibility: hidden;
      width: auto;
      background-color: var(--ion-color-dark);
      color: var(--ion-color-white);
      text-align: center;
      padding: 5px;
      border-radius: 6px;

      /* Position the tooltip text */
      position: absolute;
      z-index: 1;
      margin-top: 35px;
    }

    .job-schedule-calendar-event-label-container:hover .tooltiptext {
      visibility: visible;
    }
    .job-schedule-calendar-event-week-view-container {
      border-radius: 4px;
      padding: 10px 0px 10px 10px;
      height: 100%;
      &.day {
        background-color: var(--ion-color-event-day-shift) !important;
        border-left: 4px solid var(--ion-color-day-shift) !important;
        &.completed {
          background-color: var(--ion-color-event-complete) !important;
          border-left: 4px solid var(--ion-color-primary) !important;
        }
        &.incomplete {
          background-color: pink !important;
          border-left: 4px solid palevioletred !important;
        }
        &.cancelled {
          background-color: var(--ion-color-event-cancelled) !important;
          border-left: 4px solid var(--ion-color-danger) !important;
        }
        &.pencilled {
          background-color: var(--ion-color-light-50) !important;
          border-left: 4px solid var(--ion-color-light) !important;
        }
      }
      &.night {
        background-color: var(--ion-color-event-night-shift) !important;
        border-left: 4px solid var(--ion-color-night-shift) !important;
        &.completed {
          background-color: var(--ion-color-event-complete) !important;
          border-left: 4px solid var(--ion-color-primary) !important;
        }
        &.incomplete {
          background-color: pink !important;
          border-left: 4px solid palevioletred !important;
        }
        &.cancelled {
          background-color: var(--ion-color-event-cancelled) !important;
          border-left: 4px solid var(--ion-color-danger) !important;
        }
        &.pencilled {
          background-color: var(--ion-color-light-50) !important;
          border-left: 4px solid var(--ion-color-light) !important;
        }
      }
      .ewp-rbc-time-label {
      }
      .job-schedule-calendar-week-view-grid {
        margin-top: 5px;
        .job-schedule-calendar-week-view-row {
          .job-schedule-calendar-week-view-col {
            display: flex;
            flex-direction: column;
            // align-items: center;;
            .ewp-event-week-label {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .job-schedule-calendar-status-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              justify-content: space-around;
              // padding: 5px 0px;
              .ewp-remote-icon {
                height: 25px;
                width: 25px;
                &.day {
                  color: var(--ion-color-day-shift) !important;
                  &.completed {
                    color: var(--ion-color-success) !important;
                  }
                }
                &.night {
                  color: var(--ion-color-night-shift) !important;
                  &.completed {
                    color: var(--ion-color-success) !important;
                  }
                }
              }
              .ewp-notif-status-icon {
                height: 25px;
                width: 25px;
                &.day {
                  color: var(--ion-color-day-shift) !important;
                  &.accepted {
                    color: var(--ion-color-success) !important;
                  }
                  &.pending {
                    color: var(--ion-color-warning) !important;
                  }
                }
                &.night {
                  color: var(--ion-color-night-shift) !important;
                  &.accepted {
                    color: var(--ion-color-success) !important;
                  }
                  &.pending {
                    color: var(--ion-color-warning) !important;
                  }
                }
              }

              .ewp-invoice-icon {
                &.week {
                  height: 25px;
                  width: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
  //!Custom Calendar Day View Mode
  .job-schedule-custom-calendar-day-view-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .job-schedule-custom-calendar-day-view-grid {
      .job-schedule-custom-calendar-day-view-row {
        .job-schedule-custom-calendar-day-view-event-col {
          .job-schedule-custom-calendar-day-view-card {
            &.ipad {
              max-height: 650px !important;
            }
            max-height: 950px;
            padding-bottom: 20px !important;
            height: 100%;
            box-shadow: none !important;
            background-color: var(--ion-color-tertiary);
            border-right: 1px solid var(--ion-color-light-10);
            overflow-y: auto;
            .job-schedule-calendar-event-day-view-container {
              border-radius: 4px;
              padding: 10px 0px 10px 10px;
              max-height: 102px;
              height: 100%;
              width: 100%;
              margin-bottom: 1px;
              cursor: pointer;
              &.day {
                background-color: var(--ion-color-event-day-shift) !important;
                border-left: 4px solid var(--ion-color-day-shift) !important;
                &.completed {
                  background-color: var(--ion-color-event-complete) !important;
                  border-left: 4px solid var(--ion-color-primary) !important;
                }
                &.incomplete {
                  background-color: pink !important;
                  border-left: 4px solid palevioletred !important;
                }
                &.pencilled {
                  background-color: var(--ion-color-light-50) !important;
                  border-left: 4px solid var(--ion-color-light) !important;
                }
                &.cancelled {
                  background-color: var(--ion-color-event-cancelled) !important;
                  border-left: 4px solid var(--ion-color-danger) !important;
                }
              }
              &.night {
                background-color: var(--ion-color-event-night-shift) !important;
                border-left: 4px solid var(--ion-color-night-shift) !important;
                &.completed {
                  background-color: var(--ion-color-event-complete) !important;
                  border-left: 4px solid var(--ion-color-primary) !important;
                }
                &.incomplete {
                  background-color: pink !important;
                  border-left: 4px solid palevioletred !important;
                }
                &.pencilled {
                  background-color: var(--ion-color-light-50) !important;
                  border-left: 4px solid var(--ion-color-light) !important;
                }
                &.cancelled {
                  background-color: var(--ion-color-event-cancelled) !important;
                  border-left: 4px solid var(--ion-color-danger) !important;
                }
              }
              .job-schedule-calendar-day-view-grid {
                height: 100%;
                .job-schedule-calendar-day-view-row {
                  height: 100%;
                  .job-schedule-calendar-day-view-col {
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    .ewp-event-day-label {
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      &.truckName {
                        margin-left: 6px;
                        margin-right: 25px;
                      }
                    }
                    .job-schedule-calendar-status-container {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      height: 100%;
                      justify-content: space-evenly;
                      // padding: 5px 0px;
                      .ewp-remote-icon {
                        height: 25px;
                        width: 25px;
                        &.day {
                          color: var(--ion-color-day-shift) !important;
                          &.completed {
                            color: var(--ion-color-success) !important;
                          }
                        }
                        &.night {
                          color: var(--ion-color-night-shift) !important;
                          &.completed {
                            color: var(--ion-color-success) !important;
                          }
                        }
                      }
                      .ewp-notif-status-icon {
                        height: 25px;
                        width: 25px;
                        &.day {
                          color: var(--ion-color-day-shift) !important;
                          &.accepted {
                            color: var(--ion-color-success) !important;
                          }
                          &.pending {
                            color: var(--ion-color-warning) !important;
                          }
                        }
                        &.night {
                          color: var(--ion-color-night-shift) !important;
                          &.accepted {
                            color: var(--ion-color-success) !important;
                          }
                          &.pending {
                            color: var(--ion-color-warning) !important;
                          }
                        }
                      }

                      .ewp-invoice-icon {
                        &.day {
                          height: 25px;
                          width: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .job-schedule-custom-calendar-day-view-calendar-col {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          width: 100%;
          margin-top: 30px;
        }
      }
    }
  }
}
.job-schedule-calendar-event-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-schedule-calendar-export-dialog {
  --height: auto;
  --width: auto;
  --min-width: 697px;
  --border-radius: 5px;
  --box-shadow: none;
  &::part(content) {
    max-width: 100%;
    width: auto;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .export-dialog-container {
    margin: 50px 60px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .export-dialog-close {
      cursor: pointer;
      position: absolute;
      right: 5%;
      top: 7%;
    }
    .export-dialog-sub-header {
      margin-bottom: 25px;
    }
    .export-date-range-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .export-date-picker {
        // width: 577px;
        height: 56px;
        width: 100%;

        &.from {
          padding-right: 5px;
        }
        &.to {
          padding-left: 5px;
        }
      }
    }

    .export-dialog-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 50px;
      .export-dialog-button {
        --border-radius: 4px;
        width: 179px;
        height: 44px;
        &.cancel {
          width: 75px !important;
        }
      }
    }
  }
}
.invoice-container .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-white);
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  right: 0px;
  // margin-top: 5px;
}
.invoice-container:hover .tooltiptext {
  visibility: visible;
}
