.user-details-title {
  margin-bottom: 20px;
}
.user-details-row {
  margin-bottom: 28px;
}
.user-start-col {
  padding-right: 10px;
}

.user-end-col {
  padding-left: 10px;
}

.user-add-sites-details-title {
  margin-top: 50px;
}

.user-sites-dropdown-container {
  margin-top: 20px;
  margin-bottom: 50px;
}
.user-access-dropdown-container {
  margin-top: 50px;
  .user-access-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .user-access-label {
      margin-right: 6px;
    }
  }
}

.user-access-tooltip-pop-over {
  --box-shadow: rgba(14, 12, 34, 0.05);
  --background: var(--ion-color-tertiary);
  --max-width: 350px;
  --width: 100%;
  text-align: start;
  margin-top: 10px;
  .tool-tip-label-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    .tool-tip-label {
      margin-bottom: 5px;
    }
  }
}
