@import "../../theme/shared";

.ewp-page-title-and-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ewp-title-div {
    color: var(--ion-color-medium);
    letter-spacing: -0.48px;
    text-align: center;
    margin-bottom: 10px;
  }
  .ewp-description-div {
    color: var(--ion-color-light);
    line-height: 20px !important;

    &.clamp {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    p {
      margin: 0;
    }

    &.align-left {
      width: 80%;
    }
  }
}
