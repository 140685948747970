.search-register-container {
  --padding-bottom: 224px;
  .search-title-label-container {
    margin-bottom: 30px;
  }
  .search-button-input-container {
    display: flex;
    flex-direction: row;
    .search-category-button {
      min-height: 56px;
      min-width: 190px;
      border: 1px solid var(--ion-color-light-10);
      --background: var(--ion-color-white);
      --box-shadow: none !important;
      .search-button-icon {
        margin-left: 10px;
        height: 15px;
        width: 15px;
      }
    }
    .search-job-date-pickers {
      display: flex;
      flex-direction: row;
      align-self: center;
      margin-left: 30px;
      justify-content: space-evenly;
      width: 100%;
      .toggle-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .toggle-box-item {
          max-width: 100%;
          --inner-padding-end: 0;
          .toggle-checkbox {
            --background: #f3f3f7;
            --border-radius: 4px;
            --border-color: #f3f3f7;
            --size: 21px;
            margin-left: 0px;
          }
          .toggle-checkbox-label {
            flex: none;
            margin-left: 11px;
            padding-right: 11px;
          }
        }
      }
    }
    .ewp-title-search-button-search-bar {
      --background: var(--ion-color-white);
      max-width: 407px;
      min-height: 58px;

      align-self: flex-end;
      border: 1px solid var(--ion-color-light-10);
      border-radius: 4px;
      input {
        box-shadow: none !important;
      }
    }
  }
  .search-table-container {
    margin-top: 40px;
    &.ipad {
      overflow-x: auto;
    }
    .date-attachment-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .attachment-button {
        height: 22px;
        width: 22px;
        margin-right: 5px;
        --background: #fafafc;
        .attachment-icon {
          color: var(--ion-color-gray-contrast);
          height: 22px;
          width: 22px;
          background: url("../../../assets/icons/attachment.svg") no-repeat
            center center;
        }
      }
    }
  }
}
