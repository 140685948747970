.nav-content-grid-container {
  height: 100%;
  .nav-content-row-container {
    height: 100%;
  }
  .nav-content-col-container {
    padding: 0;
    height: auto;
    background-color: var(--ion-color-tertiary);
    width: 256px !important;
    max-width: 256px !important;
  }

  .home-container {
    padding: 40px 20px 0px 40px;
    &.is-tablet {
      padding: 30px 20px 0px 30px;
    }
  }
}
