.client-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .client-register-header-container {
  }
  .client-list-header-padding {
    margin-bottom: 10px;
  }

  .client-list-table-container {
    display: flex;
    flex-direction: column;
    .MuiTableCell-stickyHeader {
      z-index: 9999 !important;
    }
  }
}
