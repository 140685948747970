@import "../../theme/shared.scss";

.ewp-side-nav-list {
  background: var(--ion-color-tertiary);
  .list-md {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.ewp-web-side-nav-icon-text-item {
  border-bottom: 1px solid rgba(118, 116, 128, 0.03);
  padding: 0px 17px;
  --border-radius: 4px;

  &.is-first {
    border-top: 1px solid rgba(118, 116, 128, 0.03);
  }

  .ewp-web-side-nav-icon-text-container {
    display: flex;
    align-items: center;
    margin-left: 3px;

    .ewp-web-side-nav-label {
      margin: 25px 95px 24px 24px;
    }

    .ewp-web-side-nav-icon {
      position: relative;
      width: $ewpIconNavigatorWidth;
      height: $ewpIconNavigatorHeight;

      &.job-schedule-white {
        background: url("../../assets/icons/job-schedule-white.svg") no-repeat
          center center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.job-schedule-gray {
        background: url("../../assets/icons/job-schedule-gray.svg") no-repeat
          center center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.employee-white {
        background: url("../../assets/icons/employee-white.svg") no-repeat
          center center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.employee-gray {
        background: url("../../assets/icons/employee-gray.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.truck-white {
        background: url("../../assets/icons/truck-white.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.truck-gray {
        background: url("../../assets/icons/truck-gray.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.company-white {
        background: url("../../assets/icons/company-white.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.company-gray {
        background: url("../../assets/icons/company-gray.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.users-white {
        background: url("../../assets/icons/users-white.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.users-gray {
        background: url("../../assets/icons/users-gray.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.admin-gray {
        background: url("../../assets/icons/admin-gray.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
      &.admin-white {
        background: url("../../assets/icons/admin-white.svg") no-repeat center
          center;
        background-size: $ewpIconNavigatorHeight $ewpIconNavigatorWidth;
      }
    }
  }
}
