.ewp-title-detail-page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ewp-title-detail-page-back-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ewp-title-detail-page-container-back-button {
      --border-radius: 5px;
      height: 44px;
      width: 44px;
      margin-right: 24px;
      --background: var(--ion-color-gray);
      --background-hover: var(--ion-color-gray-tint);
      --background-activated: var(--ion-color-gray-shade);
      --box-shadow: none;
      .ewp-title-detail-page-container-back-icon {
        color: var(--ion-color-gray-contrast);
        height: 15px;
        width: 15px;
      }
    }
  }
  .ewp-title-detail-page-action-buttons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }
  .ewp-title-detail-page-create-button {
    border: 1px solid var(--ion-color-primary);
    border-radius: 4px;
    max-width: 200px;
    width: 100%;
    height: 54px;
  }
  .ewp-title-detail-page-new-button {
    position: relative;
    max-width: 200px;
    width: 100%;
    height: 54px;
    --border-radius: 4px;
    margin-left: 20px;
    .add-icon {
      position: absolute;
      left: 11px;
      margin-right: 11px;
      width: 18px;
      height: 18px;
      background: url("../../assets/icons/add-icon.svg") no-repeat center center;
    }

    .edit-icon {
      position: absolute;
      left: 11px;
      margin-right: 11px;
      width: 12;
      height: 12;
      background: url("../../assets/icons/edit-icon.svg") no-repeat center
        center;
    }
  }
}
