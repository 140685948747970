.driver-job-detail-site-docket {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 50px;
  .driver-job-detail-site-docket-header-container {
    height: auto;
    margin-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--ion-color-light-10);
    .driver-job-detail-site-docket-grid {
      height: 100%;
      .driver-job-detail-site-docket-row {
        height: 100%;
        .driver-job-detail-site-docket-col {
          height: 100%;
          width: 100%;
          align-self: center;
          &.shift {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .driver-job-detail-site-docket-shift {
            height: 24px;
            width: 24px;
            border-radius: 4px;
            &.day {
              background-color: var(--ion-color-day-shift) !important;
              &.completed {
                background-color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                background-color: pink !important;
              }
            }
            &.night {
              background-color: var(--ion-color-night-shift) !important;
              &.completed {
                background-color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                background-color: pink !important;
              }
            }
          }
          .driver-job-detail-site-docket-header-label-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .driver-job-detail-site-docket-header-label {
              &.day {
                color: var(--ion-color-day-shift) !important;
                &.completed {
                  color: var(--ion-color-primary) !important;
                }
                &.incomplete {
                  color: pink !important;
                }
              }
              &.night {
                color: var(--ion-color-night-shift) !important;
                &.completed {
                  color: var(--ion-color-primary) !important;
                }
                &.incomplete {
                  color: pink !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.driver-job-detail-site-docket-details-container {
  border-bottom: 1px solid var(--ion-color-light-10);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .job-site-docket-date-label {
    text-align: left;
    margin-bottom: 10px;
    align-self: flex-start;
  }
  .job-site-docket-add-files-button {
    background-color: var(--ion-color-tertiary);
    border: 2px dashed var(--ion-color-light-15);
    max-width: 327px;
    width: 100%;
    height: 87px;
    border-radius: 10px;
    .add-files-icon {
      height: 24px;
      width: 24px;
      background: url("../../../assets/icons/add-files-icon.svg") no-repeat
        center center;
    }
    .add-files-label {
      margin-left: 7px;
    }
  }
  .job-site-docket-not-applicable-container {
    width: 100%;
    --padding-start: 0;
    --inner-padding-start: 0;
    .job-site-docket-not-applicable {
      margin-left: 3px;
      // --background: #f3f3f7;
      --border-radius: 4px;
      // --border-color: #f3f3f7;
      --size: 21px;
    }
  }
  .site-docket-attachment-grid {
    width: 100%;
    .site-docket-attachment-row {
      height: calc(100vw / 3.2);
      .site-docket-attachment-img-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-right: 5px;
        width: 100%;
        .site-docket-attachment-img {
          height: 100%;
          max-height: 93px;
          object-fit: cover;
          overflow: hidden;
          position: relative;
          border-radius: 5px;
          margin-right: 5px;
          width: 90%;
        }

        .site-docket-delete-attachment {
          color: white;
          height: 20px;
          width: 20px;
          --border-radius: 50%;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

.add-add-attachment-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .add-attachment-button {
    --border-radius: 5px;
    --background: var(--ion-color-gray);
    --background-hover: var(--ion-color-gray-tint);
    --background-activated: var(--ion-color-gray-shade);
    --box-shadow: none;
    width: 183px !important;
    height: 44px !important;
    --border-radius: 4px;
    &.has-attachments {
      margin-top: 24px !important;
    }
    .add-attachment-button-icon {
      position: absolute;
      margin-left: 13px;
      left: 0;
      width: 15px;
      height: 15px;
      background: url(../../../assets/icons/add-icon-black.svg) no-repeat center
        center;
    }
  }
}
