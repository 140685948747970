.ewp-table-spinner-container {
  height: 51vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ewp-table {
  tr,
  td {
    padding: 12px 16px 10px 16px !important;

    &:hover {
      background-color: unset !important;
    }

    &.clickable {
      cursor: pointer;
      &:hover {
        background-color: var(--ion-color-light-10) !important;
      }
    }
  }

  th {
    background-color: var(--ion-color-primary);
    line-height: unset !important;
    height: 42px !important;
    border-bottom: unset !important;
    padding: 16px 0px 16px 16px;
  }
  .MuiTableSortLabel-icon {
    height: 13px;
    width: 13px;
    margin-bottom: 3px;
    color: white !important;
  }
  .ewp-table-row-data {
    z-index: -1;
  }
  .MuiTableCell-root {
    border-bottom-color: var(--ion-color-light-10);
  }
}
