.ewp-title-search-button-container {
  --inner-padding-end: 0;
  // margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  .ewp-title-search-button-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .ewp-title-search-button-toggle-box-item {
      max-width: 100%;
      --inner-padding-end: 0;
      margin-right: 20px;
      .ewp-title-search-button-toggle-checkbox {
        margin-left: 11px;
        --background: #f3f3f7;
        --border-radius: 4px;
        --border-color: #f3f3f7;
        --size: 21px;
      }
      .ewp-title-search-button-toggle-checkbox-label {
        flex: none;
        margin-left: 11px;
        padding-right: 11px;
      }
    }
  }
  .ewp-title-search-button-search-bar {
    --background: var(--ion-color-white);
    max-width: 290px;
    min-height: 54px;

    align-self: flex-end;
    border: 1px solid var(--ion-color-light-10);
    border-radius: 4px;
    input {
      box-shadow: none !important;
    }
  }

  .ewp-title-search-button-create-new {
    position: relative;
    margin-left: 20px;
    height: 54px;
    width: 200px;
    --border-radius: 4px;
    --padding-right: 0px;
    --box-shadow: none;
    .add-icon {
      position: absolute;
      left: 11px;
      margin-right: 11px;
      width: 18px;
      height: 18px;
      background: url("../../assets/icons/add-icon.svg") no-repeat center center;
    }
  }
}
