.ewp-detail-card {
  --inner-padding-end: 0;
  max-height: 280px;
  min-width: 1200px;
  height: 100%;
  width: 100%;
  &.tablet {
    min-width: 0px !important;
  }
  .ewp-detail-card-content {
    .ewp-detail-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 20px 25px;

      .ewp-detail-card-header-button {
        max-width: 163px;
        width: 100%;
        --border-radius: 4px;
        --background: var(--ion-color-gray);
        --background-hover: var(--ion-color-gray-tint);
        --background-activated: var(--ion-color-gray-shade);
        --box-shadow: none;
      }
    }
    .ewp-detail-card-grid {
      border-top: 1px solid var(--ion-color-light-10);
      max-height: 280px;
      height: 100%;
      overflow-y: auto;
      .ewp-detail-card-row {
        height: 100%;
        min-height: 280px;
        //detail card column
        .ewp-detail-card-col-container {
          height: 100%;
          padding: 40px;
          min-height: 280px;
        }
        .ewp-detail-card-truck-grid {
          .ewp-detail-card-truck-row {
            .ewp-detail-card-truck-col {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .ewp-detail-truck-label-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
                .ewp-detail-card-truck-detail-title-label {
                  margin-right: 5px;
                }
                .ewp-detail-card-truck-detail-label {
                  width: 100%;
                  text-align: left;
                }
              }
            }
          }
        }
        //detail icon column
        .ewp-detail-card-icon-col {
          height: 100%;
          .ewp-detail-card-icon {
            height: 134px;
            width: 134px;
            &.client {
              background: url("../../assets/icons/company-card-icon.svg")
                no-repeat center center;
            }
            &.truck {
              background: url("../../assets/icons/truck-card-icon.svg")
                no-repeat center center;
            }
          }
        }
        //details column
        .ewp-detail-card-detail-col {
          height: 100%;
          border-left: 1px solid var(--ion-color-light-10);
          .ewp-detail-card-detail-title-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
          }
          .ewp-detail-card-detail-sub-details-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
        //sub details / description card column
        .ewp-detail-card-sub-detail-col {
          border-left: 1px solid var(--ion-color-light-10);
          .ewp-detail-contents-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .ewp-detail-card-contact-person-label {
              margin-bottom: 15px;
              text-align: start;
              width: 100%;
            }
            .ewp-detail-card-truck-description {
              margin-top: 15px;
              text-align: start;
              &.tablet {
                max-width: 300px;
                width: 100%;
                height: 100px;
                overflow-x: scroll;
              }
            }
            .contact-person-icon {
              height: 12px;
              width: 12px;
              margin-right: 10px;
              &.person {
                background: url("../../assets/icons/person-small-icon.svg")
                  no-repeat center center;
              }
              &.number {
                background: url("../../assets/icons/number-small-icon.svg")
                  no-repeat center center;
              }
              &.email {
                height: 10px;
                width: 16px;
                background: url("../../assets/icons/email-small-icon.svg")
                  no-repeat center center;
              }
            }
          }
        }
      }
    }
  }
}
