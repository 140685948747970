.ewp-user-account-toolbar {
  --background: var(--ion-color-tertiary);
  box-shadow: 0px -2px 8px rgba(44, 48, 54, 0.05);
  .ewp-account-footer-button {
    flex: 1;
    height: 80px;
    .ewp-account-footer-icon {
      &.driver {
        height: 36px;
        width: 36px;
        background: url(../../../assets/icons/driver-head-icon.svg) no-repeat
          center center;

        &.active {
          background: url(../../../assets/icons/driver-head-icon-active.svg)
            no-repeat center center;
        }
      }
      &.jobSchedule {
        height: 36px;
        width: 36px;
        background: url(../../../assets/icons/driver-job-schedule-calendar.svg)
          no-repeat center center;

        &.active {
          background: url(../../../assets/icons/driver-job-schedule-calendar-active.svg)
            no-repeat center center;
        }
      }
      &.logout {
        height: 36px;
        width: 36px;
        background: url(../../../assets/icons/logout-mobile.svg) no-repeat
          center center;

        &.active {
          background: url(../../../assets/icons/logout-mobile.svg) no-repeat
            center center;
        }
      }
    }
  }
}
