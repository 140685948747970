.driver-job-detail {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .driver-job-detail-header-container {
    height: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    .driver-job-detail-grid {
      height: 100%;
      .driver-job-detail-row {
        height: 100%;
        .driver-job-detail-col {
          height: 100%;
          width: 100%;
          align-items: center;
          &.shift {
            display: flex;
            flex-direction: column;
            align-self: center;
          }
          .driver-job-detail-shift {
            height: 24px;
            width: 24px;
            border-radius: 4px;
            &.day {
              background-color: var(--ion-color-day-shift) !important;
              &.completed {
                background-color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                background-color: pink !important;
              }
            }
            &.night {
              background-color: var(--ion-color-night-shift) !important;
              &.completed {
                background-color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                background-color: pink !important;
              }
            }
          }
          .driver-job-detail-header-label-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .driver-job-detail-header-label {
              &.day {
                color: var(--ion-color-day-shift) !important;
                &.completed {
                  color: var(--ion-color-primary) !important;
                }
                &.incomplete {
                  color: pink !important;
                }
              }
              &.night {
                color: var(--ion-color-night-shift) !important;
                &.completed {
                  color: var(--ion-color-primary) !important;
                }
                &.incomplete {
                  color: pink !important;
                }
              }
            }
            .job-id-label {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

.driver-job-detail-details-container {
  border-top: 1px solid var(--ion-color-light-10);
  border-bottom: 1px solid var(--ion-color-light-10);
  &.details {
    border-top: none !important;
    border-bottom: none !important;
    padding: 24px 24px 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .detail-icon-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    .detail-icon {
      height: 20px;
      width: 20px;
      margin-right: 15px;
      &.client {
        background: url("../../../assets/icons/company-gray.svg") no-repeat
          center center;
      }
      &.contact-person {
        background: url("../../../assets/icons/fullname-driver-job-detail.svg")
          no-repeat center center;
      }
      &.phone-number {
        background: url("../../../assets/icons/number-driver-job-detail.svg")
          no-repeat center center;
      }
      &.truck {
        background: url("../../../assets/icons/truck-driver-job-detail.svg")
          no-repeat center center;
      }
      &.address {
        background: url("../../../assets/icons/address-driver-job-detail.svg")
          no-repeat center center;
        width: 25px;
      }
      &.notes {
        background: url("../../../assets/icons/notes-driver-job-detail.svg")
          no-repeat center center;
        width: 40px;
      }
    }
    .detail-label {
      text-align: start;
    }
  }
  .job-attachments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
    .job-attachments-title {
      margin-bottom: 10px;
      align-self: flex-start;
    }
    .job-attachment-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      width: 100%;
      .job-attachment-avatar {
        --size: 95px;
        --border-radius: 5px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      // .icon-label-container {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      // .job-attachment-img-icon {
      //   width: 20px;
      //   height: 16px;
      //   background: url("../../../assets/icons/img-thumbnail-icon.svg")
      //     no-repeat center center;
      // }
      // .job-attachment-date-label {
      // margin-left: 15px;
      // }
      // }
    }
  }

  .driver-job-detail-map-container {
    width: 100%;
    height: 100%;
    min-height: 200px;
    background-color: var(--ion-color-tertiary);
    margin-bottom: 15px;
  }
  .driver-job-detail-remote-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 24px 24px 24px;
    background-color: var(--ion-color-light-gray);
    .remote-detail-title-header {
      text-align: center;
    }
    .remote-details-container {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      &.stop-points {
        align-items: flex-start;
        flex-direction: column;
      }
      .remote-details-title {
        &.stop-points {
          margin-bottom: 10px;
        }
      }
      .remote-details-value {
        &.stop-points {
          text-align: start;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.driver-job-detail-site-dockets-container {
  border-bottom: 1px solid var(--ion-color-light-10);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
  .driver-site-dockets-title {
    margin-bottom: 15px;
    align-self: flex-start;
  }
  .site-dockets-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    width: 100%;

    .icon-label-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .site-docket-img-icon {
        width: 20px;
        height: 16px;
        background: url("../../../assets/icons/img-thumbnail-icon.svg")
          no-repeat center center;
      }
      .site-docket-date-label {
        margin-left: 15px;
      }
    }
  }
  .edit-docket-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    .edit-docket-button {
      --border-radius: 5px;
      --background: var(--ion-color-gray);
      --background-hover: var(--ion-color-gray-tint);
      --background-activated: var(--ion-color-gray-shade);
      --box-shadow: none;
      width: 233px !important;
      height: 44px !important;
      --border-radius: 4px;
      &.has-attachments {
        margin-top: 24px !important;
      }
      .edit-docket-button-icon {
        position: absolute;
        margin-left: 13px;
        left: 0;
        width: 16px;
        height: 16px;
        background: url(../../../assets/icons/edit-docket-icon.svg) no-repeat
          center center;
      }
    }
  }
}

.driver-job-detail-button-container {
  width: 100%;
  margin: 20px 0px;
  .driver-job-detail-button {
    --border-radius: 4px;
    --height: 100%;
    min-height: 54px;
    --width: 100%;
    min-width: 327px;
  }
}
