@import "../../../theme/shared.scss";

.mobile-attachment-dialog {
  --background: rgba(32, 36, 42, 0.9) !important;
  &::part(content) {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    justify-content: flex-end; // contain: content;
    // background-color: red;
    .modal-content {
      overflow: auto;
    }
    // .ion-page {
    //   contain: content;
    // }
  }
  .mobile-attachment-dialog-header {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .mobile-attachment-dialog-header-close {
      --padding-top: 5px;
      --padding-end: 5px;
      --padding-bottom: 5px;
      --padding-start: 5px;
      margin-top: 30px;
      margin-right: 24px;
      height: 40px;
      width: 40px;
    }
    .mobile-attachment-dialog-title-html {
      width: 100%;
      p {
        margin: 0;
        font-size: $ewpH4FontSize !important;
        font-weight: $ewpH4FontWeight !important;
        color: var(--ion-color-medium) !important;
      }
    }
  }

  .mobile-attachment-dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .attachment-image {
      position: relative;
    }
    .image-label {
      margin-top: 10px;
      align-self: center;
    }
    .worker-licenses-icon-img {
      height: 92px;
      width: 92px;
      position: relative;
      align-self: center;
      // background: url(../../../assets/icons/licensing-yellow-huge.svg) no-repeat
      //   center center;
    }

    .attachment-empty-placeholder {
      position: relative;
      align-self: center;
    }

    .attachment-prev-button {
      position: absolute;
      left: 0;
      width: 54px;
      z-index: 1;
    }
    .attachment-next-button {
      position: absolute;
      right: 0;
      width: 54px;
      z-index: 1;
    }

    .attachment-next-prev-icon {
      width: 40px;
      height: 40px;
    }
    .attachment-delete-button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 25px;
      height: 40px;
      margin: 24px;
      .mobile-delete-icon {
        background: url(../../../assets/icons/mobile-delete-icon.svg) no-repeat
          center center;
        width: 23px;
        height: 29px;
      }
    }
    .attachment-edit-button {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 5px;
      border: 1px solid var(--ion-color-secondary);
      margin: 24px;
      height: 44px;
      width: 88px;
    }
  }
}
