@import "../../../theme/shared";

.driver-sign-in-page {
  visibility: hidden;

  &.safe-to-view {
    visibility: visible;
    opacity: 1 !important;
  }

  .sign-in-content {
    --padding-end: 24px;
    --padding-start: 24px;

    &.has-keyboard {
      --padding-bottom: 150px;
    }

    .sign-in-title-container {
      margin: 58px 42px 56px;
    }
    .sign-in-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 70%;
    }
    .sign-in-email-input-container {
      margin-bottom: 25px;
    }
    .forgot-password {
      margin: 20px;
      margin-bottom: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .forgot-password-spiel {
        vertical-align: middle;
      }
      .forgot-password-reset-link {
        margin-left: 5px;
      }
      .sign-in-terms-privacy-container {
        margin-top: 64px;
      }
    }
  }
}
