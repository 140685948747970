.ewp-table-spinner-container {
  height: 51vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ewp-job-history-card {
  --inner-padding-end: 0;
  max-height: 400px;
  min-height: 400px;
  // min-width: 1200px;
  width: 100%;
  height: 100%;
  .ewp-job-history-card-content {
    .ewp-job-history-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 20px 25px;
      .ewp-job-history-card-header-button {
        max-width: 163px;
        width: 100%;
        --border-radius: 4px;
        --background: var(--ion-color-gray);
        --background-hover: var(--ion-color-gray-tint);
        --background-activated: var(--ion-color-gray-shade);
        --box-shadow: none;
      }
    }
    .ewp-job-history-card-grid {
      border-top: 1px solid var(--ion-color-light-10);
      max-height: 280px;
      height: 100%;
      padding: 0px 20px;
      overflow-y: auto;
      .ewp-job-history-card-row-content-header {
        padding: 15px 0px;
        border-bottom: 1px solid var(--ion-color-light-10);
      }
      .ewp-job-history-card-row-content-data {
        cursor: pointer;
        border-bottom: 1px solid var(--ion-color-light-10);
        padding: 2px 0px;
        .ewp-job-history-view-site-docket {
          cursor: pointer;
        }

        .location {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      :hover.ewp-job-history-card-row-content-data {
        background-color: var(--ion-color-light-10) !important;
      }
    }
  }
}
