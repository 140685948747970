.ewp-form-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .ewp-form-page-back-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ewp-form-page-back-button {
      --border-radius: 5px;
      height: 44px;
      width: 44px;
      margin-right: 24px;
      --background: var(--ion-color-gray);
      --background-hover: var(--ion-color-gray-tint);
      --background-activated: var(--ion-color-gray-shade);
      --box-shadow: none;
      .ewp-form-page-container-back-icon {
        color: var(--ion-color-gray-contrast);
        height: 15px;
        width: 15px;
      }
    }
  }
  .ewp-form-page-children-container {
    width: 100%;
    height: auto;
    margin: 60px 0px 0px 150px;
    display: flex;
    align-items: center;
  }
}
