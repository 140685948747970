@import "../../theme/shared.scss";

.delete-expiry-dialog {
  &::part(content) {
    --border-radius: 5px;
    height: 405px;
    width: 697px;
    box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  }

  .delete-expiry-content {
    --padding-top: 50px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;

    .delete-expiry-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .delete-expiry-title-container {
      display: flex;
      flex-direction: column;

      .delete-expiry-subtitle {
        margin-top: 13px;
      }
    }

    .delete-expiry-card {
      --inner-padding-end: 0;
      margin-top: 49px;
      height: 110px;
      width: min-content;
      min-width: 350px;
      margin: 30px auto;
      box-shadow: none !important;

      .delete-expiry-card-content {
        .delete-expiry-card-item {
          background: var(--ion-color-white);
          display: flex;
          align-items: center;
          .delete-expiry-icon {
            height: 46px;
            width: 46px;
            margin: 28px 35px 28px 40px;
            background: url("../../assets/icons/calendar_large_green.svg")
              no-repeat center center;
          }
          .delete-expiry-dialog-detail-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .delete-expiry-dialog-truck-grid {
            margin-left: 40px;
            .delete-expiry-dialog-truck-row {
              .delete-expiry-dialog-truck-col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .delete-expiry-dialog-truck-detail-label {
                  max-width: 250px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
          .delete-expiry-driver-details {
            margin-left: 70px;
          }
        }
      }
    }

    .delete-expiry-action-buttons-item {
      margin-top: 50px;
    }
  }
}
