.expiries-card {
  --inner-padding-end: 0;
  min-height: 280px;
  min-width: 597px;
  width: 100%;
  height: 100%;
  &.tablet {
    min-width: 0px !important;
  }
  .expiries-card-content {
    .expiries-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 20px 25px;
      .expiries-card-header-button {
        max-width: 163px;
        width: 100%;
        --border-radius: 4px;
        --background: var(--ion-color-gray);
        --background-hover: var(--ion-color-gray-tint);
        --background-activated: var(--ion-color-gray-shade);
        --box-shadow: none;
      }
    }
    .expiries-card-grid {
      border-top: 1px solid var(--ion-color-light-10);
      max-height: 280px;
      height: 100%;
      padding: 0px 20px;
      overflow-y: auto;
      .ewp-table-spinner-container {
        height: 30vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
      .expiries-card-row-content-header {
        padding: 15px 0px;
        border-bottom: 1px solid var(--ion-color-light-10);
      }
      .expiries-card-row-content-data {
        // cursor: pointer;
        border-bottom: 1px solid var(--ion-color-light-10);
        padding: 2px 0px;
        .expiries-view-site-docket {
          // cursor: pointer;
        }
        .expiries-card-buttons-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .expiries-attachment-button {
            height: 22px;
            width: 22px;
            margin-right: 5px;
            --background: #fafafc;
            .expiries-attachment-icon {
              color: var(--ion-color-gray-contrast);
              height: 22px;
              width: 22px;
              background: url("../../assets/icons/attachment.svg") no-repeat
                center center;
            }
          }
          .expiries-attachment-button {
            height: 22px;
            width: 22px;
            margin-right: 5px;
            --background: #fafafc;
            .expiries-attachment-icon {
              color: var(--ion-color-gray-contrast);
              height: 22px;
              width: 22px;
              background: url("../../assets/icons/attachment.svg") no-repeat
                center center;
            }
          }
          .expiries-edit-button {
            height: 22px;
            width: 22px;
            --background: #fafafc;
            .expiries-edit-icon {
              color: var(--ion-color-gray-contrast);
              height: 22px;
              width: 22px;
              background: url("../../assets/icons/edit-small-light.svg")
                no-repeat center center;
            }
          }
        }
      }
      :hover.expiries-card-row-content-data {
        background-color: var(--ion-color-light-10) !important;
      }
    }
  }
}
