.ewp-induction-row {
  margin-bottom: 28px;
  .expiry-date-col {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .induction-upload-container {
    margin-top: 30px;
    width: 100%;
    .induction-title-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      .induction-attachments-add-another-button {
        width: 140px;
        height: 30px;
        border-radius: 4px;
        background: var(--ion-color-gray);
        .induction-small-add-icon {
          margin-left: 10px;
          position: absolute;
          left: 0;
        }
      }
    }
    .web-upload-list-attachment-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: auto;
      height: 100%;
      width: 100%;
      .web-upload-attachment-container {
        width: 105px;
        position: relative;
        padding-top: 5px;
        .remove-attachment-button {
          height: 24px;
          z-index: 1;
          right: 0;
          --border-radius: 50% !important;
          position: absolute;
          width: 24px;
          --box-shadow: none !important;
          margin-right: 3px;
          .web-upload-attachment-close-icon {
            position: absolute;
            font-size: 18px;
          }
        }
        .web-upload-attachment-avatar {
          --size: 95px;
          --border-radius: 5px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }

    .induction-upload-item {
      --background: rgba(78, 84, 94, 0.05);
      border-radius: 4px;
      border: 1px dashed rgba(118, 116, 128, 0.25);
      height: 100px;
      margin-top: 20px;
      .web-upload-icon-label-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100px;
        margin-left: 320px;
        .induction-upload-label {
          cursor: pointer;
        }
        .induction-upload-icon {
          margin-right: 12px;
          height: 23px;
          width: 23px;
          background: url("../../assets/icons/web-upload.svg") no-repeat center
            center;
        }
      }
    }
    .ewp-induction-date-input-date {
      position: relative;
      // min-width: 290px;
      width: 100% !important;
      --border-width: 0 !important;
      --background: var(--ion-color-white) !important;
      --box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
    }
    .remove-expiry {
      position: absolute;
      right: -50px;
      top: 9px;
    }
  }
  .ewp-induction-start-col {
    padding-right: 10px;
    .date-with-remove-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      .expiry-date-input-date {
        position: relative;
        max-width: 414px;
        width: 100% !important;
        --border-width: 0 !important;
        --background: var(--ion-color-white) !important;
        --box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
      }
    }
  }
  .ewp-induction-end-col {
    padding-left: 10px;
    &.delete {
      display: flex;
      justify-content: flex-end;
    }
    .delete-expiry-button {
      --border-radius: 4px;
      max-width: 163px;
      height: 30px;
      width: 100%;
      margin-bottom: 30px;
    }
    .expiries-date-input-date {
      width: 100% !important;
      height: 56px;
      width: 100%;
      --border-color: unset;
      --background: white;
      --box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
    }
  }
}
