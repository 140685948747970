.driver-send-notes-page {
  width: 100%;
  height: 100%;
  .driver-job-detail-header-container {
    height: auto;
    margin-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--ion-color-gray);
    .driver-job-detail-grid {
      height: 100%;
      .driver-job-detail-row {
        height: 100%;
        .driver-job-detail-col {
          height: 100%;
          width: 100%;
          align-items: center;
          &.shift {
            display: flex;
            flex-direction: column;
            align-self: center;
          }
          .driver-job-detail-shift {
            height: 24px;
            width: 24px;
            border-radius: 4px;
            &.day {
              background-color: var(--ion-color-day-shift) !important;
              &.completed {
                background-color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                background-color: pink !important;
              }
            }
            &.night {
              background-color: var(--ion-color-night-shift) !important;
              &.completed {
                background-color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                background-color: pink !important;
              }
            }
          }
          .driver-job-detail-header-label-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .driver-job-detail-header-label {
              &.day {
                color: var(--ion-color-day-shift) !important;
                &.completed {
                  color: var(--ion-color-primary) !important;
                }
                &.incomplete {
                  color: pink !important;
                }
              }
              &.night {
                color: var(--ion-color-night-shift) !important;
                &.completed {
                  color: var(--ion-color-primary) !important;
                }
                &.incomplete {
                  color: pink !important;
                }
              }
            }
            .job-id-label {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .driver-send-notes {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    text-align: right;
    .note-title-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .driver-notes-title {
        text-align: left;
      }
      .driver-note-edit-button {
        width: 63px;
        height: 30px;
      }
      .driver-note-delete-button {
        width: 80px;
        height: 30px;
        padding-left: 8px;
      }
    }
    .note-status {
      padding-right: 8px;
    }
    .driver-notes-submit-button {
      margin-top: 30px;
    }
  }
}
