.title-description-contianer {
  display: flex;
  flex-direction: column;
  .driver-company-details-title {
    margin-bottom: 20px;
  }
  .driver-personal-details-title {
    margin: 20px 0px;
    &.page {
      margin-top: 0px !important;
      text-align: left;
    }
  }
}

.driver-form-grid {
  padding: calc(24px + var(--ion-safe-area-top)) 24px 24px 24px;
}
.driver-contact-person-details-row {
  margin-bottom: 28px;
  &.page {
    margin-bottom: 0px !important;
  }
}
.ewp-driver-start-col {
  padding-right: 10px;
  &.page {
    padding-right: 0px !important;
    margin-bottom: 20px;
  }

  .job-schedule-remote-item {
    --inner-padding-end: 0;
  }
}
.ewp-driver-end-col {
  padding-left: 10px;
  &.page {
    padding-left: 0px !important;
    margin-bottom: 20px;
  }
}

.driver-mobile-form-submit-container {
  padding: 24px 24px calc(24px + var(--ion-safe-area-top)) 24px;
  .ewp-form-page-save-form-button {
    width: 100%;
    height: 68px;
    --box-shadow: none !important;
  }
}
