.ewp-tool-tip {
  --box-shadow: 0 0 10px rgba(14, 12, 34, 0.05) !important;
  --background: var(--ion-color-white);
  --height: auto;
  --min-width: 460px;

  .ewp-tooltip-container {
    display: flex;
    height: auto;
    width: 100%;
    align-items: center;
    .ewp-tooltip-label {
      padding: 25px;
    }
  }
}
