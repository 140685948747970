@import "../../theme/shared.scss";

.ewp-input-item-container {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
  border-radius: 4px;
  &.readOnly {
    box-shadow: none !important;
  }
  &.item-has-value {
    .ewp-web-label-input {
      color: var(--ion-color-primary);
    }
  }
  &.item-has-error-class {
    .ewp-web-label-input {
      color: var(--ion-color-danger);
    }
  }
  &.item-has-focus {
    .ewp-web-label-input {
      color: var(--ion-color-primary);
    }
  }
  .ewp-web-label-input {
    opacity: 1 !important;
    z-index: 9999;
    position: absolute;
    padding-left: 20px;
    padding-top: 0px;
    line-height: 19px;
    color: var(--ion-color-light);
    margin-top: 0px !important;
    &.mobile {
      margin-top: 0px !important;
    }
  }

  .ewp-web-input {
    background-color: var(--ion-color-tertiary);
    border-radius: 4px !important;
    --padding-start: 16px !important;
    --padding-end: 20px;
    --padding-top: 23px !important;
    max-width: 100%;
    height: 56px;
    line-height: $ewpBodyLineHeight;
    font-size: $ewpBodyFontSize;
    font-weight: $ewpBodyFontWeight;
    &.text-area {
      min-height: 140px;

      .textarea-wrapper {
        textarea {
          min-height: 140px;
        }
      }
    }
    input {
      height: 100%;
    }
    &.has-value {
      --padding-start: 15px !important;
      --padding-bottom: 8px !important;
    }
    &.has-error-class {
      border: 1px solid var(--ion-color-danger) !important;
      --padding-start: 14px !important;
      --padding-bottom: 8px !important;
    }
    &.has-focus {
      border: 1px solid var(--ion-color-primary) !important;
      --padding-start: 14px !important;
      --padding-bottom: 8px !important;
    }

    &.searchbar-has-value {
      .searchbar-input-container {
        .searchbar-input {
          padding-top: 23px !important;
          padding-left: 15px !important;
        }
      }
    }
    &.searchbar-has-error-class {
      border: 1px solid var(--ion-color-danger) !important;
      --placeholder-color: var(--ion-color-danger) !important;
      .searchbar-input-container {
        .searchbar-input {
          padding-left: 14px !important;
        }
      }
    }
    &.searchbar-has-focus {
      border: 1px solid var(--ion-color-primary) !important;
      .searchbar-input-container {
        .searchbar-input {
          --placeholder-opacity: 0 !important; // hide placeholder if on focus
          padding-top: 23px !important;
          padding-left: 14px !important;
        }
      }
    }

    &.md {
      margin-top: 0px;
    }

    input.native-input[disabled] {
      opacity: 0.5 !important;
    }
  }
}
