.archived-banner-indicator {
  --inner-padding-end: 0;
  border-radius: 5px;
  box-shadow: none !important;
  .archived-banner-indicator-item {
    --background: var(--ion-color-danger);
    --inner-padding-end: 0;
    --padding-start: 0;
    height: 40px;
    display: flex;
    align-items: center;

    .archived-banner-indicator-icon {
      height: 18px;
      width: 18px;
      position: absolute;
      left: 10px;
      background: url("../../assets/icons/archive-small-white.svg") no-repeat
        center center;
    }

    .archived-banner-indicator-label {
      text-align: center;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .archived-banner-indicator-button {
        height: 12px;
        width: 62px;
        font-weight: bold;
        border-bottom: 1px solid var(--ion-color-tertiary);
        margin: 2px 5px 0 5px;
      }
    }
  }
}
