.ewp-terms-and-privacy {
  text-align: center;
  opacity: 0.5;
  z-index: 9999;
  .ewp-divider {
    vertical-align: sub;
  }
  margin: auto;
  max-width: 80%;
}

.iframe-dialog {
  --border-radius: 4px;
  &::part(content) {
    max-height: 100%;
    justify-content: flex-start;
    .modal-content {
      overflow: auto;
    }
    // .ion-page {
    //   contain: content;

    // }
  }
  .iframe-dialog-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .iframe-dialog-header-close {
      --padding-top: 5px;
      --padding-end: 5px;
      --padding-bottom: 5px;
      --padding-start: 5px;
      margin-top: 30px;
      margin-right: 24px;
      height: 40px;
      width: 40px;
    }
  }
  .iframe-dialog-title-container {
    border-top: 1px solid black;
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    .iframe {
      height: 100%;
      width: 100%;
    }
  }
}
