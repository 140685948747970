.driver-profile-detail-details-container {
  --inner-padding-end: 0;
  height: 110px;
  box-shadow: none !important;

  .driver-profile-detail-details-card {
    width: 100%;
  }

  .driver-profile-detail-details-item {
    --background: var(--ion-color-white);
    --inner-padding-end: 0;
    --padding-start: 0;

    .driver-profile-detail-profile-icon {
      height: 54px;
      width: 54px;
      margin: 28px 41px;
      background: url("../../assets/icons/profile-header-icon.svg") no-repeat
        center center;
    }

    .driver-profile-detail-details-item-details {
      align-self: center;

      .driver-profile-detail-assign-to-subcontractor {
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-radius: 0;
        height: 15px;
        vertical-align: text-top;
      }
    }
    .driver-details-col {
      align-self: center;
    }
    .driver-profile-detail-details-action-item-container {
      --background: var(--ion-color-white);
      --inner-padding-end: 0;
      --padding-start: 0;
      --border-width: 0 0 0 1px;
      --border-color: var(--ion-color-light-10);
      .driver-profile-detail-contact-info {
        padding-left: 40px;
      }
      .driver-profile-detail-details-blacklist-button {
        height: 30px;
        width: 163px;
        --border-radius: 4px;
        .driver-profile-detail-blacklist-icon {
          color: var(--ion-color-gray-contrast);
          height: 12px;
          width: 12px;
          position: absolute;
          left: 8px;
          background: url("../../assets/icons/archived-driver.svg") no-repeat
            center center;
        }
        .driver-profile-blacklist-button-label {
          margin-left: 10px;
          vertical-align: top;
        }
      }

      .driver-profile-detail-details-email-button {
        margin-right: 60px;
        margin-left: 20px;
        height: 30px;
        width: 120px;
        --box-shadow: none;
        --border-radius: 4px;
        &.tablet {
          margin-right: 30px !important;
        }
      }
    }
  }
}
