.job-schedule-custom-calendar-day-view-card {
  margin: 0px 20px 20px 20px;
  .job-schedule-calendar-event-day-view-container {
    margin: 20px 0px;
    border-radius: 4px;
    padding: 10px 10px 10px 10px;
    min-height: 90px;
    height: 100%;
    width: 100%;
    margin-bottom: 1px;
    background-color: var(--ion-color-tertiary);
    cursor: pointer;
    &.day {
      // background-color: var(--ion-color-event-day-shift) !important;
      border-left: 4px solid var(--ion-color-day-shift) !important;
      &.completed {
        background-color: var(--ion-color-event-complete) !important;
        border-left: 4px solid var(--ion-color-primary) !important;
      }
      &.incomplete {
        background-color: pink !important;
        border-left: 4px solid palevioletred !important;
      }
    }
    &.night {
      // background-color: var(--ion-color-event-night-shift) !important;
      border-left: 4px solid var(--ion-color-night-shift) !important;
      &.completed {
        background-color: var(--ion-color-event-complete) !important;
        border-left: 4px solid var(--ion-color-primary) !important;
      }
      &.incomplete {
        background-color: pink !important;
        border-left: 4px solid palevioletred !important;
      }
    }
    .job-schedule-calendar-day-view-grid {
      height: 100%;
      .job-schedule-calendar-day-view-row {
        height: 100%;
        .job-schedule-calendar-day-view-col {
          display: flex;
          flex-direction: row;
          align-items: center;
          .driver-job-schedule-detail-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .driver-job-schedule-label {
            margin: 3px 0px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            &.day {
              color: var(--ion-color-day-shift) !important;
              &.completed {
                color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                color: palevioletred !important;
              }
            }
            &.night {
              color: var(--ion-color-night-shift) !important;
              &.completed {
                color: var(--ion-color-primary) !important;
              }
              &.incomplete {
                color: palevioletred !important;
              }
            }
          }
          .job-schedule-calendar-status-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: space-evenly;
            .ewp-remote-icon {
              height: 30px;
              width: 30px;
              &.day {
                color: var(--ion-color-day-shift) !important;
                &.completed {
                  color: var(--ion-color-success) !important;
                }
              }
              &.night {
                color: var(--ion-color-night-shift) !important;
                &.completed {
                  color: var(--ion-color-success) !important;
                }
              }
            }
            .ewp-notif-status-icon {
              height: 30px;
              width: 30px;
              &.day {
                color: var(--ion-color-day-shift) !important;
                &.accepted {
                  color: var(--ion-color-success) !important;
                }
                &.pending {
                  color: var(--ion-color-warning) !important;
                }
              }
              &.night {
                color: var(--ion-color-night-shift) !important;
                &.accepted {
                  color: var(--ion-color-success) !important;
                }
                &.pending {
                  color: var(--ion-color-warning) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
