.driver-account {
  visibility: hidden;

  &.safe-to-view {
    visibility: visible;
    opacity: 1 !important;
  }

  .driver-account-profile {
    width: 100%;
    text-align: justify;
    margin-bottom: 50px;
    .driver-account-profile-photo-container {
      width: 125px;
      height: 125px;
      margin: 34px auto 19px auto;
      --inner-padding-end: 0;
    }

    .driver-account-container {
      margin: 0 24px 10px;
      &.first {
        margin-top: 24px;
      }
    }
  }
}
