@import "../../../theme/shared.scss";

.ewp-note-entry-card {
  max-width: 720px;
  margin-top: 20px;
  width: 100%;
  .ewp-note-entry-card-header {
    padding: 18px 24px 21px 24px;
    border-bottom: 1px solid var(--ion-color-light-10);
    .ewp-note-entry-card-header-design-container {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .ewp-note-entry-card-header-hole {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: var(--ion-color-secondary);
      }
    }
    .ewp-note-entry-card-header-title-and-button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        font-size: $ewpH4FontSize !important;
        font-weight: $ewpH4FontWeight !important;
        color: var(--ion-color-medium) !important;
      }

      .ewp-note-entry-card-edit-button {
        width: 63px;
        height: 30px;
      }
      .ewp-note-entry-card-delete-button {
        width: 80px;
        height: 30px;
        padding-left: 8px;
      }
    }
  }
  .ewp-note-entry-card-content {
    padding: 17px 48px 34px 24px;

    .ewp-note-entry-content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .daily-diary-card-date-time-container {
        margin-top: 17px;
        display: flex;
        flex-direction: column;
      }

      .ewp-note-entry-details {
        margin-top: 10px;
        font-size: $ewpParagraphFontSize !important;
        font-weight: $ewpParagraphFontWeight !important;
        color: var(--ion-color-light) !important;
      }
    }

    .daily-diary-card-attachment-list-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: auto;
      height: 100%;
      margin-top: 21px;

      .daily-diary-card-attachment-container {
        width: 105px;
        position: relative;
        padding-right: 10px;
        .daily-diary-card-attachment-avatar-button {
          height: 95px;
          width: 95px;
          margin-top: 10px;

          .daily-diary-card-attachment-avatar {
            --size: 95px;
            --border-radius: 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
  }
}
