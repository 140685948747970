@import "../../theme/shared.scss";

.ewp-note-attachement-note-title-input {
  height: 56px !important;
  min-width: 672px;
  margin-bottom: 20px;
  width: 100%;
  text-align: start;
  --padding-start: 16px !important;
  cursor: text;
  box-shadow: 0 0 5px 0 rgba(14, 12, 34, 0.05);
  border: 1px solid var(--ion-color-light-10);
  background-color: var(--ion-color-tertiary);
  border-radius: 4px !important;
  max-width: 100%;
  line-height: $ewpBodyLineHeight;
  font-size: $ewpBodyFontSize;
  font-weight: $ewpBodyFontWeight;
  input {
    height: 100%;
  }

  &.has-error-class {
    border: 1px solid var(--ion-color-danger) !important;
  }
  &.has-focus {
    border: 1px solid var(--ion-color-primary) !important;
  }
  input.native-input[disabled] {
    opacity: 0.5 !important;
  }
}

.ewp-note-attachment-body-container {
  height: auto;
  width: 100%;
  font-size: $ewpParagraphFontSize;
  font-weight: $ewpParagraphFontWeight;
  text-align: start;
  display: flex;
  flex-direction: column;
  background: var(--ion-color-tertiary);
  border-radius: 4px;
  max-width: 672px;
  padding-top: 5px;
  cursor: text;
  border: 1px solid var(--ion-color-light-10);
  box-shadow: 0 0 5px 0 rgba(14, 12, 34, 0.05);
  .ewp-note-attachement-note-input {
    width: inherit;
    padding-left: 16px;
  }

  &.has-focus {
    border: 1px solid var(--ion-color-primary) !important;
  }
  .ewp-note-attachment-list-attachment-container {
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    align-items: center;
    height: auto;
    margin-right: 20px;
    margin-left: 20px;
    .ewp-note-attachment-attachment-container {
      width: 105px;
      position: relative;
      padding-right: 10px;
      padding-top: 5px;
      .remove-attachment-button {
        height: 24px;
        z-index: 1;
        right: 0;
        --border-radius: 50% !important;
        position: absolute;
        width: 24px;
        --box-shadow: none !important;
        margin-right: 3px;
        .ewp-note-attachment-attachment-close-icon {
          position: absolute;
          font-size: 18px;
        }
      }
      .ewp-note-attachment-attachment-avatar {
        --size: 95px;
        --border-radius: 5px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }

  .ewp-note-attachment-attachment-button {
    align-self: flex-start;
    margin-left: 20px;
    margin-bottom: 6px;
    height: 26px;
  }
}
