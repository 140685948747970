.title-description-contianer {
  display: flex;
  flex-direction: column;
  .driver-company-details-title {
    margin-bottom: 20px;
  }
  .driver-personal-details-title {
    margin: 20px 0px;
    &.page {
      margin-top: 0px !important;
      text-align: left;
    }
  }
}

.driver-form-grid {
  margin-top: 40px;
  &.page {
    margin-top: 0px !important;
  }
}
.driver-contact-person-details-row {
  margin-bottom: 28px;
  &.page {
    margin-bottom: 0px !important;
  }
}
.ewp-driver-start-col {
  padding-right: 10px;
  &.page {
    padding-right: 0px !important;
    margin-bottom: 20px;
  }
}
.ewp-driver-end-col {
  padding-left: 10px;
  &.page {
    padding-left: 0px !important;
    margin-bottom: 20px;
  }
}

.ewp-form-page-save-form-button {
  margin-top: 50px;
  width: 100%;
  height: 68px;
  --box-shadow: none !important;
}
