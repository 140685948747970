.admin-container {
  width: 100%;
  .admin-register-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--ion-color-light-10);
  }
  .admin-tables-container {
    width: 100%;
    height: 100%;
    padding: 40px 0px;
    .admin-table-grid {
      .admin-table-row {
        .admin-table-depot-col {
          .admin-table-depot-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0px 0px 30px 0px;
            justify-content: space-between;
            .admin-table-add-depot-button {
              --border-radius: 4px;
            }
          }
        }
        .admin-table-truck-col {
          padding-right: 10px;
          margin-top: 40px;
          .admin-table-truck-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0px 0px 30px 0px;
            justify-content: space-between;
            .admin-table-add-truck-button {
              --border-radius: 4px;
            }
          }
          td {
            min-width: 200px !important;
          }
        }
        .admin-table-driver-col {
          padding-left: 10px;
          margin-top: 40px;
          .admin-table-driver-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0px 0px 30px 0px;
            justify-content: space-between;
            .admin-table-add-driver-button {
              --border-radius: 4px;
            }
          }
          td {
            min-width: 200px !important;
          }
        }
      }
    }
  }
}

.table-label-with-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .edit-button {
    margin-left: 5px;
    height: 22px;
    width: 22px;
    --background: #fafafc;
    .edit-icon {
      color: var(--ion-color-gray-contrast);
      height: 22px;
      width: 22px;
      background: url("../../../assets/icons/edit-small-light.svg") no-repeat
        center center;
    }
  }
  .delete-button {
    height: 20px;
    width: 20px;
    background-color: none !important;
    .delete-depot-icon {
      height: 15px;
      width: 15px;
    }
  }
}
