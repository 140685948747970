@import "../../theme/shared.scss";

.ewp-number-field-with-country-code-user-input-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  border-radius: 4px;
  background-color: var(--ion-color-tertiary);
  box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);

  .ewp-number-field-with-country-code-country-select {
    margin: 0;
    height: 100%;
    --border-radius: 4px 0 0 4px;
    --border-width: 0 1px 0 0;
    --border-style: solid;
    --border-color: var(--ion-color-light-10);

    --box-shadow: none;
    --padding-start: 5px;
    --padding-end: 5px;
    text-align: start;

    .ewp-number-field-with-country-code-flag-thumbnail {
      height: auto;
      width: 24px;
      margin: 17px 12px;
    }

    .ewp-number-field-with-country-code-country-code {
      margin-right: 11px;
    }

    &.has-focus {
      border: 1px solid var(--ion-color-primary) !important;
      --padding-start: 14px !important;
      --padding-bottom: 8px !important;
    }
  }

  .ewp-number-field-with-country-code-label-input-container {
    width: 100%;
    --inner-padding-end: 0;

    .ewp-number-field-with-country-code-label-input-label {
      opacity: 1 !important;
      z-index: 9999;
      position: absolute;
      padding-left: 20px;
      padding-top: 0px;
      line-height: 19px;
      color: var(--ion-color-light);
      &.has-focus {
        margin-top: 10px !important;
      }
      &.has-value {
        margin-top: 10px !important;
      }
      &.mobile {
        margin-top: 0px !important;
      }
    }
    .ewp-number-field-with-country-code-input {
      background-color: var(--ion-color-tertiary);
      border-radius: 4px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      --padding-start: 16px !important;
      --padding-end: 20px;
      --padding-top: 23px !important;
      max-width: 100%;
      // max-height: 56px;
      line-height: 19px;
      font-size: $ewpBodyFontSize;

      input {
        height: 100%;
      }

      &.has-value {
        --padding-start: 15px !important;
        --padding-bottom: 8px !important;
      }
      &.has-error-class {
        border: 1px solid var(--ion-color-danger) !important;
        border-right-width: 0 !important;
        border-bottom-width: 0 !important;
        border-top-width: 0 !important;
        --padding-start: 14px !important;
        --padding-bottom: 8px !important;
      }

      &.has-focus {
        border: 1px solid var(--ion-color-primary);
        --padding-start: 14px !important;
        --padding-bottom: 8px !important;
      }
      &.md {
        margin-top: 0px;
      }

      input.native-input[disabled] {
        opacity: 0.5 !important;
      }
    }

    &.item-has-value {
      .ewp-number-field-with-country-code-label-input-label {
        color: var(--ion-color-primary);
      }
    }

    &.item-has-focus {
      .ewp-number-field-with-country-code-label-input-label {
        color: var(--ion-color-primary);
      }
    }
  }

  &.item-has-error-class {
    border: 1px solid var(--ion-color-danger) !important;
    .ewp-number-field-with-country-code-label-input-container {
      .ewp-number-field-with-country-code-label-input-label {
        color: var(--ion-color-danger);
      }
    }
  }
}
