.fault-defect-note-add-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 90%;
  background: var(--ion-background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  // padding-bottom: 40px !important;
  .faults-and-defects-add-notes-button {
    z-index: 1;
    --border-radius: 5px;
    height: 35px;
    width: 150px;
    .faults-and-defects-add-icon {
      color: white;
      height: 18px;
      width: 18px;
      position: absolute;
      left: 5px;
      background: url("../../assets/icons/add-icon.svg") no-repeat center center;
    }
  }
}

.fault-defect-note-card {
  margin-top: 20px;
  --inner-padding-end: 0;
  min-height: 300px;
  // max-width: 720px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .fault-defect-note-card-header {
    border-bottom: 1px solid var(--ion-color-light-10);
    width: 100%;
    padding: 20px;
    display: flex;
    .fault-defect-note-card-headerlabel {
      align-self: flex-start;
      text-align: start;
    }
  }
  .fault-defect-note-container {
    height: 100%;
    margin-top: 40px;
    display: flex;
    flex-flow: column;
    // padding: 0 20px;
    // align-items: flex-end;
    max-width: 672px;
    width: 100%;
    .fault-defect-note-action-buttons-item {
      margin-top: 20px;
      width: 100%;
      margin-bottom: 20px;
      .fault-defect-note-save-button {
        width: 68px;
        height: 30px;
        --box-shadow: none;
        --border-radius: 5px;
      }
    }
  }
}

.fault-defect-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  .note-card-container {
    width: 100%;
  }
  .fault-defect-empty-container {
    width: 100%;
    border: 2px solid gray;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;

    ion-label {
      color: gray !important;
    }

    ion-icon {
      color: gray !important;
      width: 50px;
      height: 50px;
    }
  }
}
