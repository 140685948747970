.ewp-number-email-number-label {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}
.ewp-number-email-phone-icon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
  vertical-align: middle;
  background: url("../../assets/icons/number-small-icon.svg") no-repeat center
    center;
}
.ewp-number-email-message-icon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
  vertical-align: middle;
  background: url("../../assets/icons/email-small-icon.svg") no-repeat center
    center;
}
