@import "../../theme/shared.scss";

.job-schedule-detail-dialog {
  --height: auto;
  --width: auto;
  --min-width: 697px;
  --border-radius: 5px;
  --box-shadow: none;
  &::part(content) {
    min-width: 697px;
    width: 100%;
    width: auto;
    overflow: auto;
    max-height: 90vh;
    .modal-content {
      overflow: auto;
    }
  }
  .job-schedule-detail-dialog-header-item {
    --inner-padding-end: 0;
    --border-color: var(--ion-color-light-10);
    .job-schedule-detail-dialog-header-close {
      margin-top: 20px;
      margin-right: 25px;
    }
  }
  .job-schedule-detail-dialog-container {
    margin: 0px 60px 60px 60px;
    .job-schedule-detail-header-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 28px;
      .job-detail-title-notes-container {
        display: flex;
        align-items: center;
        .admin-notes-counter {
          position: absolute;
          left: 12px;
          top: 5px;
          z-index: 1;
          background: var(--ion-color-medium);
          border-radius: 50%;
          width: 15px;
          padding-right: 1px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .job-schedule-detail-header-id {
        margin-bottom: 20px;
      }
      .job-schedule-status-icon-label-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .job-schedule-detail-header-read-status {
        }
        .job-schedule-status-button {
          --border-radius: 4px;
          min-width: 179px;
        }
        .job-schedule-status-container {
          border-radius: 4px;
          min-width: 228px;
          height: 34px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          &.accepted {
            background: var(--ion-color-event-complete);
          }
          &.pending {
            background: var(--ion-color-event-warning);
          }
          &.notSent {
            background: var(--ion-color-gray);
          }

          .job-schedule-status-icon {
          }
          .job-schedule-status-label {
          }
        }
      }
    } //end header container
    .job-schedule-detail-grid {
      padding: 30px 0px;
      border-bottom: 1px solid var(--ion-color-light-10);
      border-top: 1px solid var(--ion-color-light-10);
      &.remote-job {
        border-top: none !important;
      }
      .job-schedule-detail-row {
        margin-top: 10px;
        &.remote-job-row-one {
          margin-top: 20px;
        }
        .job-schedule-detail-col {
          display: flex;
          flex-direction: row;
          align-items: center;
          .job-schedule-link {
            color: black;
          }
          &.value {
            justify-content: flex-end;
            text-align: right;
          }
          .job-schedule-detail-warning-icon {
            margin-left: 5px;
          }
          .job-schedule-detail-stop-points-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .job-schedule-detail-value-stop-point {
              padding-bottom: 5px;
            }
          }
          .job-schedule-detail-site-docket-button {
            height: 28px;
            width: 28px;
            .attachment-icon {
              color: var(--ion-color-gray-contrast);
              height: 22px;
              width: 22px;
              background: url("../../assets/icons/attachment.svg") no-repeat
                center center;
            }
          }
          .job-schedule-shift-container {
            width: 82px;
            height: 34px;
            border-radius: 4px;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &.day-shift {
              background-color: #ffc809 !important;
            }
            &.night-shift {
              background-color: #8576ed !important;
            }
          }
        }
      }
    } // end job schedulde detail grid
    .job-schedule-detail-note-container {
      padding: 30px 0px;
      border-bottom: 1px solid var(--ion-color-light-10);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .daily-diary-card-attachment-list-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: auto;
        margin-right: 10px;
        margin-top: 20px;
        width: 342px;
        .daily-diary-card-attachment-container {
          width: 105px;
          position: relative;
          padding-right: 10px;
          .daily-diary-card-attachment-avatar-button {
            height: 95px;
            width: 95px;
            margin-top: 10px;

            .daily-diary-card-attachment-avatar {
              --size: 95px;
              --border-radius: 5px;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
          }
        }
      }
      .job-schedule-detail-note-title {
      }
      .job-schedule-detail-note-value {
        max-width: 697px;
        width: 100%;
        margin-top: 15px;
        &.sent {
          margin-top: 20px !important;
        }
        &.updated {
          margin-top: 0px !important;
        }
      }
    }
    .job-schedule-detail-buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &.end {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
      .job-schedule-detail-button {
        margin-top: 30px;
        --border-radius: 4px;
        border-radius: 4px;
        height: 54px;
        max-width: 179px;
        width: 100%;
        &.close {
          max-width: 59px;
          width: 100%;
        }
        &.duplicate {
          margin-left: 20px;
          border: 1px solid var(--ion-color-primary);
        }
        &.edit {
          margin-left: 20px;
        }
      }
    }
  } //end detail dialog container
}
