.truck-dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .truck-dashboard-detail-card-container {
    height: 100%;
    width: 100%;
    margin-top: 25px;
    .archived-banner-container {
      margin: 0px 0px 20px 0px;
    }
  }

  .truck-dashboard-card-container {
    height: 100%;
    width: 100%;
    margin-top: 20px;
    .truck-dashboard-grid {
      height: 100%;
      .truck-dashboard-row {
        height: 100%;
        .truck-dashboard-voc-col {
          padding-left: 10px;
          .truck-dashboard-voc-card {
            height: 100%;
            min-height: 250px;
          }
        }
        .truck-dashboard-change-logs-col {
          padding-left: 10px;
          padding-top: 20px;
        }
        .truck-dashboard-expires-col {
          padding-right: 10px;
          .truck-dashboard-expires-card {
            height: 100%;
            min-height: 250px;
          }
        }
        .truck-dashboard-service-hsitory-col {
          padding-right: 10px;
          padding-top: 20px;
          .truck-dashboard-service-hsitory-card {
            height: 100%;
            min-height: 250px;
          }
        }
      }
    }
  }

  .truck-dashboard-note-card {
    margin-top: 20px;
    --inner-padding-end: 0;
    max-height: 500px;
    max-width: 720px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .truck-dashboard-note-card-header {
      border-bottom: 1px solid var(--ion-color-light-10);
      width: 100%;
      padding: 20px;
      display: flex;
      .truck-dashboard-note-card-headerlabel {
        align-self: flex-start;
        text-align: start;
      }
    }
    .truck-dashboard-note-container {
      height: 100%;
      margin-top: 40px;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      max-width: 672px;
      width: 100%;
      .truck-dashboard-note-action-buttons-item {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 20px;
        .truck-dashboard-note-save-button {
          width: 68px;
          height: 30px;
          --box-shadow: none;
          --border-radius: 5px;
        }
      }
    }
  }

  .truck-add-note-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .truck-add-note-button {
      width: 720px;
      height: 68px;
      --box-shadow: none;
      --border-radius: 5px;
      margin-top: 70px;
    }
  }
}
