@import "../../../theme/shared";

.ewp-mobile-landing-page {
  visibility: hidden;

  &.safe-to-view {
    visibility: visible;
    opacity: 1 !important;
  }

  .ewp-mobile-landing-container {
    height: 100%;
    --background: var(--ion-color-dark);
    .ewp-mobile-landing-wrapper-pattern {
      position: absolute;
      // background: url(../../../assets/mobile-landing-pattern.svg) no-repeat
      // center center;
      background-size: cover;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    .ewp-mobile-landing-wrapper {
      position: absolute;
      top: 0;
      // background: url(../../../assets/mobile-landing-background-w-grad.png)
      // no-repeat;
      background-size: cover;
      height: 75%;
      width: 100%;
    }
    .ewp-mobile-landing-login-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: calc(24px + var(--ion-safe-area-top)) 24px 0px 24px;
      .ewp-landing-logo-button-container {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        .ewp-mobile-landing-login-logo {
          height: 214px;
          width: 219px;
          // background: url(../../../assets/icons/mobile-landing-logo.svg)
          // no-repeat center center;
          align-self: center;
        }

        .ewp-mobile-landing-login-action-container {
          z-index: 1;
          text-align: center;
          .ewp-mobile-landing-sign-up-button {
            margin-top: 20px;
          }
        }
      }

      .ewp-mobile-landing-footer {
        padding-bottom: 30px;
      }
    }
  }
}
//override ionic toolbar border
.footer-ios ion-toolbar:first-child {
  --border-width: 0;
}
