@import "../../theme/shared.scss";

.ewp-mini-calendar-container {
  .react-calendar {
    min-height: 250px;
    height: 100%;
    width: 96%;
    border: none;

    .react-calendar__navigation {
      height: 35px;
      .react-calendar__navigation__arrow {
        background-color: transparent;
      }
      .react-calendar__navigation__next2-button {
        display: none;
      }
      .react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__navigation__next-button {
        height: 31px;
        width: 31px;
        background: url("../../assets/icons/next-mini-calendar-icon.svg")
          no-repeat center center;
        ion-icon {
          display: none;
        }
      }
      .react-calendar__navigation__prev-button {
        height: 31px;
        width: 31px;
        background: url("../../assets/icons/prev-mini-calendar-icon.svg")
          no-repeat center center;
        ion-icon {
          display: none;
        }
      }
      .react-calendar__navigation__label {
        background-color: transparent;
      }
      button[disabled] {
        background-color: transparent;
        color: var(--ion-color-light) !important;
      }
    }
    .react-calendar__year-view .react-calendar__tile {
      padding: 2em 0.5em;
    }

    &.ewp-custom-mini-calendar {
      .react-calendar__month-view__days__day {
        border-radius: 50%;
        margin: 2px;
        margin-bottom: 25px;
        &.ewp-profile-mini-calendar-tile {
          height: 34px !important;
          width: 34px !important;
          flex-basis: 12.6% !important;
          max-width: 12.6% !important;
          color: var(--ion-color-light);
          font-weight: $ewpCalendarDayFontWeight;
          background-color: transparent;
          &.react-calendar__tile--active {
            background: var(--ion-color-primary);
            color: white;
          }

          &.react-calendar__tile:disabled {
            background-color: transparent;
            color: var(--ion-color-light-10);
          }
        }
      }
      .react-calendar__tile--hasActive {
        background: var(--ion-color-primary);
        color: white;
        font-weight: 600;
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
    }
    .react-calendar__month-view__weekdays__weekday {
      font-size: $ewpWebFontSizeXSmall !important;
      font-weight: $ewpBold !important;
      color: var(--ion-color-medium);
      margin: 25px 0px;
      padding-top: 25px;
      border-top: 1px solid var(--ion-color-light-10);
      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days {
      justify-content: space-around;
    }
  }
}
