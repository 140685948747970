.welcome-page {
  .welcome-content {
    --background: white;
    .welcome-container {
      height: 100%;
      padding: 75px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .welcome-update-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ion-spinner {
          margin-right: 10px;
        }
      }
    }
  }
}
