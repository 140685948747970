@import "../../theme/shared.scss";

.address-search-input-container {
  .address-search-input-label {
    position: absolute;
    top: 7px;
    left: 16px;
    z-index: 1;
  }
  .address-search-input {
    --background: var(--ion-color-tertiary);
    --box-shadow: none;
    --placeholder-color: var(--ion-color-light);
    --placeholder-opacity: 1;

    .searchbar-input-container {
      .searchbar-input {
        padding-left: 20px;
        padding-right: 20px;
        font-size: $ewpBodyFontSize !important;
        font-weight: $ewpBodyFontWeight !important;
        color: var(--ion-color-medium) !important;
        width: 100%;
        min-width: 472px;
      }
      .searchbar-search-icon {
        display: none;
        width: 0;
        height: 0;
      }
    }
  }
}
