@import "./variables";

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  // padding: $event-padding;
  // background-color: white;
  // border: 1px solid var(--ion-color-light);
  border-radius: $event-border-radius;
  // color: $event-color;
  cursor: pointer;
  width: 100%;
  text-align: left;
  z-index: 99;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    // background-color: var(--ion-color-light) !important;
  }

  &:focus {
    // outline: 5px auto $event-outline;
  }
}

.rbc-event-label {
  @extend .rbc-ellipsis;
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
