@import "../../theme/shared.scss";

.ewp-upload-photo-container {
  position: relative;
  .ewp-upload-photo-placeholder-initials {
    background: var(--ion-color-primary);
    border-radius: 50%;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 19px;

    &.mobile {
      width: 125px;
      height: 125px;
      background: var(--ion-color-primary);
      padding-top: 32px;
    }

    .ewp-upload-photo-card-supervisor-picture-spinner {
      height: 60px;
      width: 60px;
      padding: 10px;
      position: absolute;
      top: 10px;
      left: 10px;
      &.mobile {
        height: 105px;
        width: 105px;
      }
    }
    .ewp-upload-photo-placeholder-initials-text {
      vertical-align: middle;
      font-family: $ewpFontName;
      font-size: $ewpImageFontSize;
      font-weight: $ewpImageFontWeight;
      color: var(--ion-color-white);
      text-align: center;

      &.mobile {
        line-height: $ewpImageLargeFontHeight;
        font-size: $ewpImageLargeFontSize;
        font-weight: $ewpImageLargeFontWeight;
      }
    }
  }
  .edit-button {
    height: 28px;
    z-index: 1;
    right: 0;
    --border-radius: 50% !important;
    position: absolute;
    width: 28px;
    box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);

    &.mobile {
      height: 42px;
      width: 42px;
      --box-shadow: -3px 4px 8px 0 var(--ion-color-dark-rgb-20) !important;
    }
  }
  .ewp-upload-photo-avatar {
    position: relative;
    height: 80px;
    width: 80px;

    &.mobile {
      width: 125px;
      height: 125px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .edit-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    background: url("../../assets/icons/pencil-white.svg");
    background-size: 18px 18px;
  }
}

.ewp-upload-photo-dialog {
  &::part(content) {
    height: 370px;
    width: 600px;
    --border-radius: 5px;
    --box-shadow: 0px 1px 30px 0 rgba(14, 12, 34, 0.05);
  }
  .ewp-upload-photo-action-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
    .ewp-upload-photo-change-photo-title {
      margin-top: 50px;
      padding-bottom: 50px;
      width: 100%;
      text-align: center;
    }
    .ewp-upload-photo-button-upload-container {
      width: 100%;
      border-bottom: 1px solid var(--ion-color-light-10);
      padding-bottom: 15px;
      border-top: 1px solid var(--ion-color-light-10);
      padding-top: 15px;
      text-align: center;
    }
    .ewp-upload-photo-button-removed-container {
      width: 100%;
      border-bottom: 1px solid var(--ion-color-light-10);
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }
    .ewp-upload-photo-button-cancel-container {
      padding-top: 15px;
    }
  }
}
