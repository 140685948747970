.driver-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .driver-register-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .driver-list-header-padding {
    margin-bottom: 10px;
  }

  .driver-list-table-container {
    display: flex;
    flex-direction: column;
    .MuiTableCell-stickyHeader {
      z-index: 9999 !important;
    }
  }
}

.create-new-driver-dialog {
  width: 968px !important;
  &.tablet {
    width: auto !important;
  }
}
