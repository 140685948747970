.client-dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .client-dashboard-detail-card-container {
    height: 100%;
    width: 100%;
    margin-top: 25px;
  }
  .client-dashboard-job-history-card-container {
    height: 100%;
    width: 100%;
    margin-top: 20px;
  }
  .client-dashboard-contact-people-card-container {
    height: 100%;
    width: 100%;
    margin-top: 20px;
  }
  .client-dashboard-note-card {
    margin-top: 20px;
    --inner-padding-end: 0;
    min-height: 300px;
    max-width: 720px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .client-dashboard-note-card-header {
      border-bottom: 1px solid var(--ion-color-light-10);
      width: 100%;
      padding: 20px;
      display: flex;
      .client-dashboard-note-card-headerlabel {
        align-self: flex-start;
        text-align: start;
      }
    }
    .client-dashboard-note-container {
      height: 100%;
      margin-top: 40px;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      max-width: 672px;
      width: 100%;
      .client-dashboard-note-action-buttons-item {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 20px;
        .client-dashboard-note-save-button {
          width: 68px;
          height: 30px;
          --box-shadow: none;
          --border-radius: 5px;
        }
      }
    }
  }

  .client-add-note-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .client-add-note-button {
      width: 720px;
      height: 68px;
      --box-shadow: none;
      --border-radius: 5px;
      margin-top: 70px;
    }
  }
}
