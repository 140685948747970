.job-container {
  --padding-bottom: 224px;
  .job-header-container {
    margin: 20px 0 40px 0;
  }
  .job-schedule-form-container {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
}
