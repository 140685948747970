@import "../../theme/shared.scss";

.delete-job-sched-dialog {
  &::part(content) {
    --border-radius: 5px;
    height: 405px;
    width: 697px;
    box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  }

  .delete-job-sched-content {
    --padding-top: 50px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;

    .delete-job-sched-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .delete-job-sched-title-container {
      display: flex;
      flex-direction: column;

      .delete-job-sched-subtitle {
        margin-top: 13px;
      }
    }

    .delete-job-sched-card {
      --inner-padding-end: 0;
      margin-top: 49px;
      height: 110px;
      box-shadow: none !important;

      .delete-job-sched-card-content {
        .delete-job-sched-card-item {
          --background: var(--ion-color-white);
          --inner-padding-end: 0;
          --padding-start: 0;

          .delete-job-sched-icon {
            height: 46px;
            width: 46px;
            margin: 28px 35px 28px 40px;
            background: url("../../assets/icons/calendar_large_green.svg")
              no-repeat center center;
          }
          .delete-job-sched-dialog-detail-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .delete-job-sched-dialog-truck-grid {
            margin-left: 40px;
            .delete-job-sched-dialog-truck-row {
              .delete-job-sched-dialog-truck-col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .delete-job-sched-dialog-truck-detail-label {
                  max-width: 250px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
          .delete-job-sched-driver-details {
            margin-left: 70px;
          }
        }
      }
    }

    .delete-job-sched-action-buttons-item {
      margin-top: 50px;
    }
  }
}
