@import "../../../src/theme/shared.scss";

.job-schedule-form-inputs-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  max-width: 720px;
  .job-schedule-header {
    margin-bottom: 45px;
  }
  .job-schedule-dates-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .job-schedule-dates-input-container {
      align-self: center;
      display: flex;
      .job-schedule-start-date {
        padding-left: 10px;
      }
      .job-schedule-end-date {
        padding-left: 10px;
      }
    }
  }
  .job-schedule-time-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .job-schedule-time-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .job-schedule-time-picker {
        margin-right: 20px;
        &.finish {
          width: 470px !important;
          margin-right: 0 !important;
        }
      }
      .job-schedule-shift-container {
        width: 224px;
        height: 56px;
        border-radius: 4px;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &.day-shift {
          background-color: #ffc809 !important;
        }
        &.night-shift {
          background-color: #8576ed !important;
        }
      }
    }
  }
  .job-schedule-create-client {
    align-self: flex-end;
    margin-top: 14px;
  }
  .job-schedule-create-contact {
    align-self: flex-end;
    margin-top: 14px;
  }
  .job-schedule-common-dropdown-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    .job-schedule-common-dropdown-dropdown-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 472px;
    }

    .remove-driver {
      position: absolute;
      right: -60px;
    }
  }
  .job-schedule-stop-point-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    .job-schedule-stop-point-label {
      margin-top: 15px;
    }
    .job-schedule-stop-point-input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 472px;
      margin-bottom: 40px;
      .job-schedule-stop-point-item {
        margin-bottom: 20px;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        --background-color: var(--ion-color-tertiary);
        box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
        border-radius: 4px;
        .job-schedule-stop-point-input {
          background-color: var(--ion-color-tertiary);
          border-radius: 4px !important;
          --padding-start: 16px !important;
          --padding-end: 80px;
          --padding-top: 23px !important;
          --padding-bottom: 23px !important;
          max-width: 100%;
          height: 56px;
          line-height: $ewpBodyLineHeight;
          font-size: $ewpBodyFontSize;
          font-weight: $ewpBodyFontWeight;
        }
        .job-schedule-stop-point-add-button {
          width: 50px;
          margin-right: 20px;
          --background-color: transparent;
          --border-radius: 4px;
          position: absolute;
          left: 85%;
          z-index: 999;
        }
      }
    }
  }
  .job-schedule-address-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .job-schedule-address-input {
      width: 472px;
    }
  }
  .job-schedule-booking-contact-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .job-schedule-common-input {
      width: 472px;
    }
  }
  .job-schedule-notes-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    .job-schedule-notes-input {
      width: 472px;
    }
  }

  .job-schedule-attachments-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
    .job-schedule-button-attach-list-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .job-schedule-attachment-list-attachment-container {
        display: flex;
        margin-top: 5px;
        flex-direction: row;
        align-items: center;
        height: auto;
        margin-right: 10px;
        // margin-left: 20px;
        width: 342px;
        .job-schedule-attachment-attachment-container {
          width: 105px;
          position: relative;
          padding-right: 10px;
          padding-top: 5px;
          .remove-attachment-button {
            height: 24px;
            z-index: 1;
            right: 0;
            --border-radius: 50% !important;
            position: absolute;
            width: 24px;
            --box-shadow: none !important;
            margin-right: 3px;
            .job-schedule-attachment-attachment-close-icon {
              position: absolute;
              font-size: 18px;
            }
          }
          .job-schedule-attachment-attachment-avatar {
            --size: 95px;
            --border-radius: 5px;
            margin-top: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
      .add-attachment-button {
        width: 120px;
        // margin-right: 20px;
        --background-color: transparent;
        --border-radius: 4px;
        // position: absolute;
        // left: 85%;
        // z-index: 999;
      }
    }
  }

  .job-schedule-remote-details-container {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    .job-schedule-remote-header {
      margin: 40px 0px 20px 0px;
    }
    .job-schedule-common-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .job-schedule-common-input {
        width: 472px;
      }
    }
    .job-schedule-add-stop-point {
      align-self: flex-end;
      margin: 14px 0px 40px 0px;
    }
  }

  .job-schedule-form-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    .job-schedule-form-delete-save-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .job-schedule-form-submit-button {
        --border-radius: 4px;
        min-width: 179px;
        height: 54px;
        width: 100%;
        &.delete {
          border-radius: 4px;
          margin-right: 10px;
          border: 1px solid var(--ion-color-primary);
        }
      }
    }
  }
  .job-schedule-form-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 472px;
  }
}
