.forgot-password-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
  .forgot-password-input-form-container {
    max-width: 474px;
    width: 100%;
    margin-top: 50px;
    .forgot-password-next-button {
      margin-top: 50px;
    }
    .forgot-password-link-container {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      .forgot-password-goback-link {
        margin-top: 30px;
      }
    }
  }
}
