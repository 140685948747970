@import "../../theme/shared.scss";

.ewp-calendar-popover {
  --width: auto;
}
:root {
  .react-calendar {
    min-height: 250px;
    height: 100%;
    max-width: 300px;
    min-width: 300px;
    width: 100%;
    border: none;

    .react-calendar__navigation {
      height: 35px;
      display: flex;
      padding: 0 10px;
      .react-calendar__navigation__arrow {
        background-color: transparent;
      }
      .react-calendar__navigation__label {
        background-color: transparent;
      }
      button[disabled] {
        background-color: transparent;
        color: var(--ion-color-dark);
      }
    }
    .react-calendar__year-view .react-calendar__tile {
      padding: 2em 0.5em;
    }

    &.ewp-custom-calendar {
      .react-calendar__month-view__days__day {
        border-radius: 50%;
        margin: 2px;
        &.ewp-profile-calendar-tile {
          height: 34px !important;
          width: 34px !important;
          flex-basis: 12.6% !important;
          max-width: 12.6% !important;
          color: var(--ion-color-light);
          font-weight: $ewpCalendarDayFontWeight;
          background-color: transparent;
          &.react-calendar__tile--active {
            background: var(--ion-color-primary);
            border-radius: 50%;
            color: white;
          }

          &.react-calendar__tile:disabled {
            background-color: transparent;
            color: var(--ion-color-light-10);
          }
        }
      }
      .react-calendar__tile--hasActive {
        background: var(--ion-color-primary);
        color: white;
        font-weight: 600;
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
    }
    .react-calendar__month-view__weekdays__weekday {
      font-size: $ewpCalendarWeekDayFontSize;
      font-weight: $ewpCalendarWeekDayFontWeight;

      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days {
      justify-content: space-around;
    }
  }
}
